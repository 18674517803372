import {ButtonBase, Grid, withStyles} from "@material-ui/core";
import React, {Component} from "react";
import {RootState} from "../../../redux/reducers";
import Footer from "../../partials/Footer";
import Header from "../../partials/Header";
import PageView from "../../partials/PageView";
import styles from "./Home.styles";
import {connect} from "react-redux";
import {NavLink} from "react-router-dom";
import Helmet from "react-helmet";
import classNames from "classnames";
import BigTitle from "../../text/BigTitle";
import TinyTitle from "../../text/TinyTitle";
import WatchVideo from "../../../assets/img/Watchvideo.svg";
import NormalTitle from "../../text/NormalTitle";
import NormalText from "../../text/NormalText";
import StyledButton from "../../button/StyledButton";
import ContactForm from "../contact/ContactForm";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import Calendar from "../../../assets/img/Calendar.svg";
import People from "../../../assets/img/persons.svg";
import Caroussel from "../../reviewcaroussel/Caroussel";
import TranslatedComponent from "../../TranslatedComponent";
import Translate from "../../../Translate/Translate";
import reviewsNL from "./reviews/reviewsNL";
import reviewsEN from "./reviews/reviewsEN";
import reviewsFR from "./reviews/reviewsFR";
import axios from 'axios';
import {casahertaAnonymousApiURL} from '../../../config/axios';
import './Calendar.css';
import '../../player/Modal-Video.scss';
import ModalVideo from 'react-modal-video'

interface State {
    height: number;
    date: any;
    calendarOpen: boolean;
    adults: any;
    children: any;
    disabledDates: any;
    accomodationID: any;
    isOpen: any;
}

interface OwnProps {
    classes?: any;

}

interface StateProps {
    windowWidth: number | undefined;
    windowHeight: number | undefined;
    currentLocale: string;
}

type Props = OwnProps & StateProps;

class Home extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            height: 0,
            date: undefined,
            calendarOpen: false,
            adults: 1,
            children: undefined,
            disabledDates: undefined,
            accomodationID: undefined,
            isOpen: false
        };
        this.openModal = this.openModal.bind(this)
    }


    handleScroll = () => {
    };

    async componentDidMount() {
        this.setState({
            height: window.innerHeight,
        });

        var date = new Date();
        var startDate = new Date(date.getFullYear(), date.getMonth(), 1).getTime()
        var endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0).getTime();

        const {adults, children} = this.state;

        await this.searchAccomodations(startDate, endDate, adults ?? 1, children ?? 0);

        await this.getDisabledDates(startDate, endDate);
    }

    onDateChange = (date) => {
        this.setState({date, calendarOpen: false});
    };

    openTheCalendar = () => {
        this.setState({
            calendarOpen: true,
        });
    };

    openModal() {
        this.setState({isOpen: true})
    }

    formatDateEN = (date: any): string => {
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        return `${year}-${month}-${day}`;
    };

    doBookingSearch = (e: any) => {
        e.preventDefault();

        const {date, adults, children} = this.state;
        const today = new Date();
        const nextDay = new Date();
        const nextWeek = new Date();
        nextDay.setDate(today.getDate() + 1);
        nextWeek.setDate(today.getDate() + 7);
        const dateArray = [nextDay, nextWeek];

        const currentURL = window.location.href;
        let url = "";
        if (currentURL.indexOf("skillmedia-staging") > -1) {
            url = "http://chbooking.skillmedia-staging.be";
        }
        if (currentURL.indexOf("casaherta.com") > -1) {
            url = "https://booking.casaherta.com";
        }
        if (currentURL.indexOf("localhost:3000") > -1) {
            url = "http://localhost:3001";
        }
        const firstDate = new Date(date ? date[0] : dateArray[0]);
        const secondDate = new Date(date ? date[1] : dateArray[1]);
        const firstDateString = this.formatDateEN(firstDate);
        const secondDateString = this.formatDateEN(secondDate);
        url += `/${firstDateString}/${secondDateString}/${parseInt(
            adults,
        )}/${parseInt(children || 0)}`;
        window.open(url, "_blank");
    };

    setAdults = (adults: any) => {
        this.setState({
            adults,
        });
    };

    setChildren = (children: any) => {
        this.setState({
            children,
        });
    };

    closeTheCalendar = () => {
        this.setState({
            calendarOpen: false,
        });
    };

    getPlanning = async (startDate, endDate, accommodationId) => {
        try {
            const {data} = await axios.get(`${casahertaAnonymousApiURL}/booking/planning/${accommodationId}/${startDate}/${endDate}`);
            return data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log('error message: ', error.message);
            } else {
                console.log('unexpected error: ', error);
            }
        }
    }

    getDisabledDates = async (startDate, endDate) => {
        const {accomodationID} = this.state;
        if (accomodationID) {
            const plannings = await this.getPlanning(startDate, endDate, accomodationID);
            var disabledDates: any[] = [];
            for (var i = 0; i < plannings.planning.length; i++) {
                var item = plannings.planning[i];
                if (item.status !== 'available') {
                    const date = new Date(item.date);
                    disabledDates.push(date);
                }
            }

            this.setState({
                disabledDates
            })
        }
    }


    searchAccomodations = async (checkin, checkout, adults, children) => {
        try {
            const {data} = await axios.post(`${casahertaAnonymousApiURL}/booking/search`, {
                checkin,
                checkout,
                adults,
                children,
            });

            if (data && data.accomodations) {
                this.setState({
                    accomodationID: data.accomodations[0]._id,
                })
            }

        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log('error message: ', error.message);
            } else {
                console.log('unexpected error: ', error);
            }
        }
    }

    startDateChange = async (action, activeStartDate, value, view) => {
        const startDate = new Date(action.activeStartDate.getFullYear(), action.activeStartDate.getMonth(), 1).getTime()
        const endDate = new Date(action.activeStartDate.getFullYear(), action.activeStartDate.getMonth() + 1, 0).getTime();
        ;
        await this.getDisabledDates(startDate, endDate);
    }

    render() {
        const {classes, windowHeight, windowWidth, currentLocale} = this.props;
        const {height, date, calendarOpen, adults, children, disabledDates} = this.state;
        const mobile = windowWidth && windowWidth < 700 ? true : false;
        const theWindowHeight = mobile ? height : windowHeight || 700;
        const adultChildren = [...Array(10)];

        let reviews: any = [];
        switch (currentLocale) {
            case "nl":
                reviews = reviewsNL;
                break;
            case "fr":
                reviews = reviewsFR;
                break;
            case "en":
                reviews = reviewsEN;
                break;
            default:
                reviews = reviewsEN;
                break;
        }

        return (
            <TranslatedComponent {...this.props}>
                <PageView>
                    <Helmet>
                        <title>
                            Casa Herta d'Ibiza | {Translate.translate("HOME_TITLE")}
                        </title>
                        <meta
                            name="title"
                            content={
                                "Casa Herta d'Ibiza |" + Translate.translate("HOME_TITLE")
                            }
                        />
                        <meta
                            name="description"
                            content="Casa Herta d'Ibiza is a luxurious villa in the heart of Ibiza. Book your stay now and experience a lavish Ibiza lifestyle. The house offers a swimmingpool, bedrooms, bathrooms and suits up to 7 guests."
                        />
                        <meta
                            name="keywords"
                            content="casa, herta, ibiza, home, vacation, holiday, rental, summer, stay, island, beaches, party"
                        />
                    </Helmet>
                    <Grid
                        container
                        className={classNames(
                            classes.wideBlock,
                            classes.head,
                            classes.headmin,
                        )}
                    >
                        <Grid
                            container
                            style={{
                                flex: 0,
                            }}
                        >
                            <Header/>
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            className={classNames(classes.wide, classes.headTop)}
                            style={{
                                flex: 1,
                            }}
                        >
                            <div style={{textAlign: "center"}}>
                                <TinyTitle
                                    style={{
                                        textAlign: "center",
                                        marginBottom: 10,
                                        marginTop: 50,
                                    }}
                                >
                                    {Translate.translate("HOME_WELCOME")}
                                </TinyTitle>
                                <BigTitle
                                    style={{
                                        maxWidth: 700,
                                        textAlign: "center",
                                        marginBottom: 20,
                                    }}
                                >
                                    {Translate.translate("HOME_BOOK")}
                                </BigTitle>
                                <ButtonBase onClick={this.openModal}
                                            style={{display: "none"}}
                                >
                                    <img
                                        src={WatchVideo}
                                        alt="watch video"
                                        width="178"
                                        height="40"
                                    />
                                </ButtonBase>
                                <ModalVideo
                                    channel='youtube'
                                    isOpen={this.state.isOpen}
                                    videoId='LdxIrjZLxPo'
                                    onClose={() => this.setState({isOpen: false})}
                                />
                            </div>
                        </Grid>
                        <Grid
                            container
                            style={{
                                flex: 0,
                                display: "flex",
                                backgroundColor: "#ffdeba",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <Grid container className={classes.reservationHolder}>
                                <form action="https://booking.casahertaibiza.com" method="get">
                                    <div className={classes.reservationBox}>
                                        <div className={classes.reservationSplitter}/>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                            }}
                                        >
                                            <img
                                                src={Calendar}
                                                width="20"
                                                height="20"
                                                style={{marginRight: 10}}
                                                alt="calendar"
                                            />
                                            {!date && (
                                                <ButtonBase onClick={() => this.openTheCalendar()}>
                                                    <p style={{marginRight: 10}}>
                                                        {Translate.translate("HOME_CHECKINOUT")}
                                                    </p>
                                                </ButtonBase>
                                            )}


                                            <DateRangePicker
                                                onChange={this.onDateChange}
                                                onCalendarClose={this.closeTheCalendar}
                                                value={date}
                                                minDate={(new Date())}
                                                calendarIcon={null}
                                                format="dd-MM-yyyy"
                                                name="checkinout"
                                                isOpen={calendarOpen}
                                                className={date ? null : classes.theDateRangePicker}
                                                tileDisabled={({date, view}) =>
                                                    (view === 'month') && disabledDates &&
                                                    disabledDates.some(disabledDate =>
                                                        date.getFullYear() === disabledDate.getFullYear() &&
                                                        date.getMonth() === disabledDate.getMonth() &&
                                                        date.getDate() === disabledDate.getDate()
                                                    )}
                                                onActiveStartDateChange={this.startDateChange}
                                            />


                                        </div>
                                        <div
                                            className={classes.reservationSplitter}
                                            style={{marginLeft: 26}}
                                        />
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                            }}
                                        >
                                            <img
                                                src={People}
                                                width="20"
                                                height="20"
                                                style={{marginRight: 10}}
                                                alt="people"
                                            />
                                            <select
                                                name="adults"
                                                className={classes.reservationSelector}
                                                value={adults || 1}
                                                onChange={(e) =>
                                                    this.setAdults(parseInt(e.currentTarget.value))
                                                }
                                            >
                                                {adultChildren.map((item, i) => (
                                                    <option value={i + 1} key={i}>
                                                        {i + 1} {Translate.translate("ADULTS")}
                                                    </option>
                                                ))}
                                            </select>
                                            <select
                                                name="children"
                                                className={classes.reservationSelector}
                                                value={children || 0}
                                                onChange={(e) =>
                                                    this.setChildren(parseInt(e.currentTarget.value))
                                                }
                                            >
                                                {adultChildren.map((item, i) => (
                                                    <option value={i} key={i}>
                                                        {i} {Translate.translate("CHILDREN")}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <button
                                            type="submit"
                                            className={classes.reservationSubmit}
                                            onClick={this.doBookingSearch}
                                        >
                                            {Translate.translate("HOME_CHECKAVAILABILITY")}
                                        </button>
                                    </div>
                                </form>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        style={{
                            flex: 0,
                            display: "flex",
                            backgroundColor: "white",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Grid
                            container
                            className={classes.pageInnerHolder}
                            style={{marginLeft: 20, marginRight: 20}}
                        >
                            <NormalTitle
                                style={{
                                    textAlign: "center",
                                    marginTop: 100,
                                    marginBottom: 20,
                                }}
                                variant="h2"
                            >
                                Mi Casa, Su Casa
                            </NormalTitle>
                            <NormalText style={{textAlign: "center"}}>
                                {Translate.translate("HOME_TOPTEXT")}
                            </NormalText>
                            <TinyTitle
                                style={{
                                    textAlign: "center",
                                    marginTop: 40,
                                }}
                            >
                                {Translate.translate("HOME_TOPTEXT2")}
                            </TinyTitle>
                            <NavLink
                                to="/thevilla"
                                style={{textDecoration: "none", color: "inherit"}}
                            >
                                <StyledButton style={{marginTop: 40, marginBottom: 70}}>
                                    {Translate.translate("HOME_VIEWSPECS")}
                                </StyledButton>
                            </NavLink>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        style={{
                            flex: 0,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            position: "relative",
                            overflow: "hidden",
                            backgroundColor: "white",
                            zIndex: -10,
                        }}
                    >
                        <div className={classes.homeMidBack}/>
                        <div className={classes.homeMidBottomBack}/>
                        <Grid container className={classes.pageInnerHolder}>
                            <Grid container className={classes.firstImagesHolder}>
                                <div className={classes.firstImages}>
                                    <div className={classes.firstImagesImg}>
                                        <div className="img1"/>
                                    </div>
                                </div>
                                <div
                                    className={classNames(classes.firstImages, "hideSmall")}
                                    style={{width: "16%"}}
                                >
                                    <div className={classes.firstImagesImg}>
                                        <div className="img2"/>
                                    </div>
                                </div>
                                <div className={classNames(classes.firstImages, "hideSmall")}>
                                    <div className={classes.firstImagesImg}>
                                        <div className="img3"/>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            className={classes.pageInnerHolder}
                            style={{marginLeft: 20, marginRight: 20}}
                        >
                            <div>
                                <NormalTitle
                                    style={{
                                        textAlign: "center",
                                        maxWidth: 600,
                                        marginTop: 140,
                                        marginBottom: 20,
                                    }}
                                    variant="h2"
                                >
                                    {Translate.translate("HOME_UNFORGETTABLE")}
                                </NormalTitle>
                            </div>
                            <div style={{width: "100%"}}>
                                <TinyTitle
                                    style={{
                                        textAlign: "center",
                                        marginTop: 20,
                                    }}
                                >
                                    {Translate.translate("HOME_WHY")}
                                </TinyTitle>
                            </div>
                            <div>
                                <NormalText
                                    style={{textAlign: "center", marginTop: 40, maxWidth: 1000}}
                                >
                                    {Translate.translate("HOME_WHYTEXT")}
                                </NormalText>
                            </div>
                            <div
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <TinyTitle
                                    style={{
                                        textAlign: "center",
                                        marginTop: 40,
                                        maxWidth: 800,
                                    }}
                                >
                                    {Translate.translate("HOME_LOCATION")}
                                </TinyTitle>
                            </div>
                            <div>
                                <NavLink
                                    to="/discover"
                                    style={{textDecoration: "none", color: "inherit"}}
                                >
                                    <StyledButton style={{marginTop: 40, marginBottom: 70}}>
                                        {Translate.translate("HOME_DISCOVER")}
                                    </StyledButton>
                                </NavLink>
                            </div>
                        </Grid>
                        <Grid container className={classes.pageInnerHolder}>
                            <Grid container className={classes.firstImagesHolder}>
                                <div className={classes.firstImages}>
                                    <div className={classes.firstImagesImg}>
                                        <div className="img4"/>
                                    </div>
                                </div>
                                <div
                                    className={classNames(classes.firstImages, "hideSmall")}
                                    style={{width: "16%"}}
                                >
                                    <div className={classes.firstImagesImg}>
                                        <div className="img5"/>
                                    </div>
                                </div>
                                <div className={classNames(classes.firstImages, "hideSmall")}>
                                    <div className={classes.firstImagesImg}>
                                        <div className="img6"/>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <div
                        style={{width: "100%", display: "flex", justifyContent: "center", background: "#EEEEEE"}}
                    >
                        <Caroussel items={reviews}/>
                    </div>
                    <Grid
                        container
                        style={{
                            flex: 0,
                            display: "flex",
                            backgroundColor: "white",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Grid
                            container
                            className={classes.pageInnerHolder}
                            style={{
                                marginTop: 120,
                                marginBottom: 120,
                                marginLeft: 20,
                                marginRight: 20,
                            }}
                        >
                            <ContactForm
                                title={Translate.translate("CONTACTUS")}
                                sendText={Translate.translate("CONTACTSEND")}
                                titleVariant="h2"
                            />
                        </Grid>
                    </Grid>
                    <Footer/>
                </PageView>
            </TranslatedComponent>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        currentLocale: state.app.currentLocale,
        windowWidth: state.app.windowWidth,
        windowHeight: state.app.windowHeight,
    };
};

export default connect(mapStateToProps, null)(withStyles(styles)(Home));
