import { Grid, withStyles } from "@material-ui/core";
import React, { Component } from "react";
import { RootState } from "../../../redux/reducers";
import Footer from "../../partials/Footer";
import Header from "../../partials/Header";
import PageView from "../../partials/PageView";
import styles from "./Terms.styles";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import classNames from "classnames";

interface State {
  height: number;
}

interface OwnProps {
  classes?: any;
}

interface StateProps {
  windowWidth: number | undefined;
  windowHeight: number | undefined;
  currentLocale: string;
}

type Props = OwnProps & StateProps;

class Contact extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      height: 0,
    };
  }

  componentDidMount() {
    this.setState({
      height: window.innerHeight,
    });
  }

  render() {
    const { classes, windowHeight, windowWidth } = this.props;
    const { height } = this.state;
    const mobile = windowWidth && windowWidth < 700 ? true : false;
    const theWindowHeight = mobile ? height : windowHeight || 700;
    return (
      <PageView>
        <Helmet>
          <title>Casa Herta d'Ibiza | Terms & Contitions</title>
          <meta
            name="title"
            content="Casa Herta d'Ibiza | Contact - Terms & Conditions"
          />
          <meta
            name="description"
            content="Casa Herta d'Ibiza is a luxurious villa in the heart of Ibiza. Book your stay now and experience a lavish Ibiza lifestyle. The house offers a swimmingpool, bedrooms, bathrooms and suits up to 7 guests."
          />
          <meta
            name="keywords"
            content="casa, herta, ibiza, home, vacation, holiday, rental, summer, stay, island, beaches, party"
          />
        </Helmet>
        <Header />
        <Grid
          container
          className={classNames(classes.wideBlock)}
          style={{
            minHeight: theWindowHeight - 100,
            overflowY: "hidden",
            position: "relative",
            marginBottom: 120,
          }}
        >
          <Grid container className={classes.inner}>
            <div>
              <h1>Terms and Conditions</h1>
              <h4>Copyright &amp; Trademarks</h4>
              <p>
                All the material contained within Casa Herta&#8217;s web pages
                is the property of Casa Herta and protected under copyright law.
              </p>
              <p>
                &#8216;Casa Herta&#8217; is a registered trademark. No material,
                textual, photographic or otherwise must be copied or reproduced
                without express written permission from Casa Herta.
              </p>
              <p>&nbsp;</p>
              <h2>Business Information</h2>
              <p>
                HERTBELGIUM - Ringlaan 39, 1853 Strombeek-Bever (Belgium) -
                BE0506743836
              </p>
              <h2>Privacy Notice</h2>
              <p>
                This is the privacy notice of Casa Herta. In this document,
                &#8220;we&#8221;, &#8220;our&#8221;, or &#8220;us&#8221; refer
                to Casa Herta
              </p>
              <p>We are a company registered in Belgium</p>
              <p>
                Our registered office is at HERTBELGIUM - Ringlaan 39, 1853
                Strombeek-Bever (Belgium) - BE0506743836
              </p>
              <p>
                <strong>How can you stop receiving emails from us?</strong>
              </p>
              <p>
                Each email sent contains an easy, automated way for you to cease
                receiving email from us, or to change your expressed interests.
                If you wish to do this, simply follow the Unsubscribe link at
                the end of any email.
              </p>
              <h2>Introduction</h2>
              <ol>
                <li>
                  This is a notice to inform you of our policy about all
                  information that we record about you. It sets out the
                  conditions under which we may process any information that we
                  collect from you, or that you provide to us. It covers
                  information that could identify you (“personal information”)
                  and information that could not. In the context of the law and
                  this notice, “process” means collect, store, transfer, use or
                  otherwise act on information.
                </li>
                <li>
                  We regret that if there are one or more points below with
                  which you are not happy, your only recourse is to leave our
                  website immediately.
                </li>
                <li>
                  We take seriously the protection of your privacy and
                  confidentiality. We understand that all visitors to our
                  website are entitled to know that their personal data will not
                  be used for any purpose unintended by them, and will not
                  accidentally fall into the hands of a third party.
                </li>
                <li>
                  We undertake to preserve the confidentiality of all
                  information you provide to us, and hope that you reciprocate.
                </li>
                <li>
                  Our policy complies with UK law accordingly implemented,
                  including that required by the EU General Data Protection
                  Regulation (GDPR).
                </li>
                <li>
                  The law requires us to tell you about your rights and our
                  obligations to you in regards to the processing and control of
                  your personal data. We do this now, by requesting that you
                  read the information provided at{" "}
                  <a href="http://www.knowyourprivacyrights.org">
                    knowyourprivacyrights.org
                  </a>
                </li>
                <li>
                  Except as set out below, we do not share, or sell, or disclose
                  to a third party, any information collected through our
                  website.
                </li>
              </ol>
              <h2>The bases on which we process information about you</h2>
              <p>
                The law requires us to determine under which of six defined
                bases we process different categories of your personal
                information, and to notify you of the basis for each category.
              </p>
              <p>
                If a basis on which we process your personal information is no
                longer relevant then we shall immediately stop processing your
                data.
              </p>
              <p>
                If the basis changes then if required by law we shall notify you
                of the change and of any new basis under which we have
                determined that we can continue to process your information.
              </p>
              <h1>
                1. Information we process because we have a contractual
                obligation with you
              </h1>
              <p>
                When you create an account on our website, buy a product or
                service from us, or otherwise agree to our terms and conditions,
                a contract is formed between you and us.
              </p>
              <p>
                In order to carry out our obligations under that contract we
                must process the information you give us. Some of this
                information may be personal information.
              </p>
              <p>We may use it in order to:</p>
              <ul>
                <li>verify your identity for security purposes</li>
                <li>sell products to you</li>
                <li>provide you with our services</li>
                <li>
                  provide you with suggestions and advice on products, services
                  and how to obtain the most from using our website
                </li>
              </ul>
              <p>
                We process this information on the basis there is a contract
                between us, or that you have requested we use the information
                before we enter into a legal contract.
              </p>
              <p>
                Additionally, we may aggregate this information in a general way
                and use it to provide class information, for example to monitor
                our performance with respect to a particular service we provide.
                If we use it for this purpose, you as an individual will not be
                personally identifiable.
              </p>
              <p>
                We shall continue to process this information until the contract
                between us ends or is terminated by either party under the terms
                of the contract.
              </p>
              <h1>2. Information we process with your consent</h1>
              <p>
                Through certain actions when otherwise there is no contractual
                relationship between us, such as when you browse our website or
                ask us to provide you more information about our business,
                including property rental, our products and services, you
                provide your consent to us to process information that may be
                personal information.
              </p>
              <p>
                Wherever possible, we aim to obtain your explicit consent to
                process this information, for example, by asking you to agree to
                our use of cookies.
              </p>
              <p>
                Sometimes you might give your consent implicitly, such as when
                you send us a message by e-mail to which you would reasonably
                expect us to reply.
              </p>
              <p>
                Except where you have consented to our use of your information
                for a specific purpose, we do not use your information in any
                way that would identify you personally. We may aggregate it in a
                general way and use it to provide class information, for example
                to monitor the performance of a particular page on our website.
              </p>
              <p>
                If you have given us explicit permission to do so, we may from
                time to time pass your name and contact information to selected
                associates whom we consider may provide services or products you
                would find useful.
              </p>
              <p>
                We continue to process your information on this basis until you
                withdraw your consent or it can be reasonably assumed that your
                consent no longer exists.
              </p>
              <p>
                You may withdraw your consent at any time by instructing us
                info@casaherta.com However, if you do so, you may not be able to
                use our website or our services further.
              </p>
              <h1>
                3. Information we process for the purposes of legitimate
                interests
              </h1>
              <p>
                We may process information on the basis there is a legitimate
                interest, either to you or to us, of doing so.
              </p>
              <p>
                Where we process your information on this basis, we do after
                having given careful consideration to:
              </p>
              <ul>
                <li>
                  whether the same objective could be achieved through other
                  means
                </li>
                <li>
                  whether processing (or not processing) might cause you harm
                </li>
                <li>
                  whether you would expect us to process your data, and whether
                  you would, in the round, consider it reasonable to do so
                </li>
              </ul>
              <p>
                For example, we may process your data on this basis for the
                purposes of:
              </p>
              <ul>
                <li>
                  record-keeping for the proper and necessary administration of
                  Casa Herta
                </li>
                <li>
                  responding to unsolicited communication from you to which we
                  believe you would expect a response
                </li>
                <li>protecting and asserting the legal rights of any party</li>
                <li>
                  insuring against or obtaining professional advice that is
                  required to manage organisational risk
                </li>
                <li>
                  protecting your interests where we believe we have a duty to
                  do so
                </li>
              </ul>
              <h1>
                4. Information we process because we have a legal obligation
              </h1>
              <p>
                We are subject to the law like everyone else. Sometimes, we must
                process your information in order to comply with a statutory
                obligation.
              </p>
              <p>
                For example, we may be required to give information to legal
                authorities if they so request or if they have the proper
                authorisation such as a search warrant or court order.
              </p>
              <p>This may include your personal information.</p>
              <h2>Specific uses of information you provide to us</h2>
              <h1>
                5. Information provided on the understanding that it will be
                shared with a third party
              </h1>
              <p>
                Our website allows you to post information with a view to that
                information being read, copied, downloaded, or used by other
                people.
              </p>
              <p>Examples include:</p>
              <ul>
                <li>posting a message our forum</li>
                <li>tagging an image</li>
                <li>
                  clicking on an icon next to another visitor’s message to
                  convey your agreement, disagreement or thanks
                </li>
              </ul>
              <p>
                In posting personal information, it is up to you to satisfy
                yourself about the privacy level of every person who might use
                it.
              </p>
              <p>
                We do not specifically use this information except to allow it
                to be displayed or shared.
              </p>
              <p>
                We do store it, and we reserve a right to use it in the future
                in any way we decide.
              </p>
              <p>
                Once your information enters the public domain, we have no
                control over what any individual third party may do with it. We
                accept no responsibility for their actions at any time.
              </p>
              <p>
                Provided your request is reasonable and there is no legal basis
                for us to retain it, then at our discretion we may agree to your
                request to delete personal information that you have posted. You
                can make a request by contacting us at info@casaherta.com
              </p>
              <h1>6. Complaints regarding content on our website</h1>
              <p>
                We attempt to moderate user generated content, but we are not
                always able to do so as soon as that content is published.
              </p>
              <p>
                If you complain about any of the content on our website, we
                shall investigate your complaint.
              </p>
              <p>
                If we feel it is justified or if we believe the law requires us
                to do so, we shall remove the content while we investigate.
              </p>
              <p>
                Free speech is a fundamental right, so we have to make a
                judgment as to whose right will be obstructed: yours, or that of
                the person who posted the content that offends you.
              </p>
              <p>
                If we think your complaint is vexatious or without any basis, we
                shall not correspond with you about it.
              </p>
              <h1>7. Information relating to your method of payment</h1>
              <p>
                Payment information is never taken by us or transferred to us
                either through our website or otherwise. Our employees and
                contractors never have access to it. Unless you have authorised
                to pay via direct bank transfer.
              </p>
              <p>
                At the point of payment, you are transferred to a secure page on
                the website of [Mollie] or some other reputable payment service
                provider. That page may be branded to look like a page on our
                website, but it is not controlled by us.
              </p>
              <h1>8. Information about your security deposit transfer</h1>
              <p>
                When you agree to set up a direct debit arrangement, the
                information you give to us is passed to our own bank Caisse
                Epargne for processing according to our instructions. We do not
                keep a copy.
              </p>
              <p>
                We keep this information only for the duration of the transfer /
                refund arrangement.
              </p>
              <p>
                We are registered under the direct debit guarantee scheme. This
                provides for the customer&#8217;s bank to refund disputed
                payments without question, pending further investigation. Direct
                debits can only be set up for payments to beneficiaries that are
                approved originators of direct debits. In order to be approved,
                these beneficiaries are subjected to careful vetting procedures.
                Once approved, they are required to give indemnity guarantees
                through their banks.
              </p>
              <h1>9. Job application and employment</h1>
              <p>
                If you send us information in connection with a job application,
                we may keep it for up to [three years] in case we decide to
                contact you at a later date.
              </p>
              <p>
                If we employ you, we collect information about you and your work
                from time to time throughout the period of your employment. This
                information will be used only for purposes directly relevant to
                your employment. After your employment has ended, we will keep
                your file for [six years] before destroying or deleting it.
              </p>
              <h1>10. Sending a message to our support team</h1>
              <p>
                When you contact us, whether by telephone, through our website
                or by e-mail, we collect the data you have given to us in order
                to reply with the information you need.
              </p>
              <p>
                We record your request and our reply in order to increase the
                efficiency of our organisation.
              </p>
              <p>
                We keep personally identifiable information associated with your
                message, such as your name and email address so as to be able to
                track our communications with you to provide a high quality
                service.
              </p>
              <h1>11. Complaining</h1>
              <p>
                When we receive a complaint, we record all the information you
                have given to us.
              </p>
              <p>We use that information to resolve your complaint.</p>
              <p>
                If your complaint reasonably requires us to contact some other
                person, we may decide to give to that other person some of the
                information contained in your complaint. We do this as
                infrequently as possible, but it is a matter for our sole
                discretion as to whether we do give information, and if we do,
                what that information is.
              </p>
              <p>
                We may also compile statistics showing information obtained from
                this source to assess the level of service we provide, but not
                in a way that could identify you or any other person.
              </p>
              <h1>12. Affiliate and business partner information</h1>
              <p>
                This is information given to us by you in your capacity as an
                affiliate of us or as a business partner.
              </p>
              <p>
                It allows us to recognise visitors that you have referred to us,
                and to credit to you commission due for such referrals. It also
                includes information that allows us to transfer commission to
                you.
              </p>
              <p>The information is not used for any other purpose.</p>
              <p>
                We undertake to preserve the confidentiality of the information
                and of the terms of our relationship.
              </p>
              <p>
                We expect any affiliate or partner to agree to reciprocate this
                policy.
              </p>
              <h2>
                Use of information we collect through automated systems when you
                visit our website
              </h2>
              <h1>13. Cookies</h1>
              <p>
                Cookies are small text files that are placed on your
                computer&#8217;s hard drive by your web browser when you visit
                any website. They allow information gathered on one web page to
                be stored until it is needed for use on another, allowing a
                website to provide you with a personalised experience and the
                website owner with statistics about how you use the website so
                that it can be improved.
              </p>
              <p>
                Some cookies may last for a defined period of time, such as one
                day or until you close your browser. Others last indefinitely.
              </p>
              <p>
                Your web browser should allow you to delete any you choose. It
                also should allow you to prevent or limit their use.
              </p>
              <p>
                Our website uses cookies. They are placed by software that
                operates on our servers, and by software operated by third
                parties whose services we use.
              </p>
              <p>
                When you first visit our website, we ask you whether you wish us
                to use cookies. If you choose not to accept them, we shall not
                use them for your visit except to record that you have not
                consented to their use for any other purpose.
              </p>
              <p>
                If you choose not to use cookies or you prevent their use
                through your browser settings, you will not be able to use all
                the functionality of our website.
              </p>
              <p>We use cookies in the following ways:</p>
              <ul>
                <li>to track how you use our website</li>
                <li>
                  to record whether you have seen specific messages we display
                  on our website
                </li>
                <li>to keep you signed in our site</li>
                <li>
                  to record your answers to surveys and questionnaires on our
                  site while you complete them
                </li>
                <li>
                  to record the conversation thread during a live chat with our
                  support team
                </li>
              </ul>
              <p>
                <strong>Use of Web Beacons</strong>
              </p>
              <p>
                When we send you emails, we may include a web beacon to allow us
                to determine the number of people who open our emails. When you
                click on a link in an email, we may record this individual
                response to allow us to customize our offerings to you. Web
                beacons collect only limited information, such as a cookie
                identifier, time and date of a page being viewed, and a
                description of the page on which the Web Beacon resides (the
                URL).
              </p>
              <p>
                Web Beacons can be refused when delivered via email. If you do
                not wish to receive Web Beacons via email, you will need to
                disable HTML images or refuse HTML (select Text only) emails via
                your email software.
              </p>
              <h1>14. Personal identifiers from your browsing activity</h1>
              <p>
                Requests by your web browser to our servers for web pages and
                other content on our website are recorded.
              </p>
              <p>
                We record information such as your geographical location, your
                Internet service provider and your IP address. We also record
                information about the software you are using to browse our
                website, such as the type of computer or device and the screen
                resolution.
              </p>
              <p>
                We use this information in aggregate to assess the popularity of
                the webpages on our website and how we perform in providing
                content to you.
              </p>
              <p>
                If combined with other information we know about you from
                previous visits, the data possibly could be used to identify you
                personally, even if you are not signed in to our website.
              </p>
              <h1>15. Our use of re-marketing</h1>
              <p>
                Re-marketing involves placing a cookie on your computer when you
                browse our website in order to be able to serve to you an advert
                for our products or services when you visit some other website.
              </p>
              <p>
                We may use a third party to provide us with re-marketing
                services from time to time. If so, then if you have consented to
                our use of cookies, you may see advertisements for our products
                and services on other websites.
              </p>
              <h2>Disclosure and sharing of your information</h2>
              <h1>16. Information we obtain from third parties</h1>
              <p>
                Although we do not disclose your personal information to any
                third party (except as set out in this notice), we sometimes
                receive data that is indirectly made up from your personal
                information from third parties whose services we use.
              </p>
              <p>No such information is personally identifiable to you.</p>
              <h1>17. Third party advertising on our website</h1>
              <p>
                Third parties may advertise on our website. In doing so, those
                parties, their agents or other companies working for them may
                use technology that automatically collects information about you
                when their advertisement is displayed on our website.
              </p>
              <p>
                They may also use other technology such as cookies or JavaScript
                to personalise the content of, and to measure the performance of
                their adverts.
              </p>
              <p>
                We do not have control over these technologies or the data that
                these parties obtain. Accordingly, this privacy notice does not
                cover the information practices of these third parties.
              </p>
              <h1>18. Credit reference</h1>
              <p>
                To assist in combating fraud, we share information with credit
                reference agencies, so far as it relates to clients or customers
                who instruct their credit card issuer to cancel payment to us
                without having first provided an acceptable reason to us and
                given us the opportunity to refund their money.
              </p>
              <h1>19. Data may be processed outside the European Union</h1>
              <p>Our website is hosted in the UK</p>
              <p>
                We may also use outsourced services in countries outside the
                European Union from time to time in other aspects of our
                business.
              </p>
              <p>
                Accordingly data obtained within the UK or any other country
                could be processed outside the European Union.
              </p>
              <p>
                For example, some of the software our website uses may have been
                developed in the United States of America or in Australia.
              </p>
              <p>
                We use the following safeguards with respect to data transferred
                outside the European Union:
              </p>
              <ul>
                <li>
                  the processor is within the same corporate group as our
                  business or organisation and abides by the same binding
                  corporate rules regarding data processing.
                </li>
                <li>
                  the data protection clauses in our contracts with data
                  processors include transfer clauses written by or approved by
                  a supervisory authority in the European Union [, specifically
                  that in the country of …]
                </li>
                <li>
                  we comply with a code of conduct approved by a supervisory
                  authority in the European Union [, specifically that in the
                  country of …]
                </li>
                <li>
                  we are certified under an approved certification mechanism as
                  provided for in the GDPR
                </li>
                <li>
                  both our organisation and the processor are public authorities
                  between whom there is either a legally binding agreement or
                  administrative arrangements approved by a supervisory
                  authority in the European Union relating to protection of your
                  information
                </li>
              </ul>
              <h2>Access to your own information</h2>
              <h1>20. Access to your personal information</h1>
              <ul>
                <li>
                  At any time you may review or update personally identifiable
                  information that we hold about you, by signing in to your
                  account on our website.
                </li>
                <li>
                  To obtain a copy of any information that is not provided on
                  our website you may send us a request at info@casaherta.com
                </li>
                <li>
                  After receiving the request, we will tell you when we expect
                  to provide you with the information, and whether we require
                  any fee for providing it to you.
                </li>
              </ul>
              <h1>21. Removal of your information</h1>
              <p>
                If you wish us to remove personally identifiable information
                from our website, you may contact us at info@casaherta.com
              </p>
              <p>This may limit the service we can provide to you.</p>
              <h1>22. Verification of your information</h1>
              <p>
                When we receive any request to access, edit or delete personal
                identifiable information we shall first take reasonable steps to
                verify your identity before granting you access or otherwise
                taking any action. This is important to safeguard your
                information.
              </p>
              <h2>Other matters</h2>
              <h1>23. Use of site by children</h1>
              <ul>
                <li>
                  We do not sell products or provide services for purchase by
                  children, nor do we market to children.
                </li>
                <li>
                  If you are under 18, you may use our website only with consent
                  from a parent or guardian
                </li>
              </ul>
              <h1>24. Encryption of data sent between us</h1>
              <p>
                We use Secure Sockets Layer (SSL) certificates to verify our
                identity to your browser and to encrypt any data you give us.
              </p>
              <p>
                Whenever information is transferred between us, you can check
                that it is done so using SSL by looking for a closed padlock
                symbol or other trust mark in your browser’s URL bar or toolbar.
              </p>
              <h1>25. How you can complain</h1>
              <ul>
                <li>
                  If you are not happy with our privacy policy or if have any
                  complaint then you should tell us by email. Our address is
                  info@casaherta.com
                </li>
                <li>
                  If a dispute is not settled then we hope you will agree to
                  attempt to resolve it by engaging in good faith with us in a
                  process of mediation or arbitration.
                </li>
                <li>
                  If you are in any way dissatisfied about how we process your
                  personal information, you have a right to lodge a complaint
                  with the Information Commissioner&#8217;s Office. This can be
                  done at{" "}
                  <a href="https://ico.org.uk/concerns/">
                    https://ico.org.uk/concerns/
                  </a>
                </li>
              </ul>
              <h1>26. Retention period for personal data</h1>
              <p>
                Except as otherwise mentioned in this privacy notice, we keep
                your personal information only for as long as required by us:
              </p>
              <ul>
                <li>to provide you with the services you have requested;</li>
                <li>
                  to comply with other law, including for the period demanded by
                  our tax authorities;
                </li>
                <li>to support a claim or defence in court.</li>
              </ul>
              <h1>27. Compliance with the law</h1>
              <p>
                Our privacy policy has been compiled so as to comply with the
                law of every country or legal jurisdiction in which we aim to do
                business. If you think it fails to satisfy the law of your
                jurisdiction, we should like to hear from you.
              </p>
              <p>
                However, ultimately it is your choice as to whether you wish to
                use our website.
              </p>
              <h1>28. Review of this privacy policy</h1>
              <p>
                We may update this privacy notice from time to time as
                necessary. The terms that apply to you are those posted here on
                our website on the day you use our website. We advise you to
                print a copy for your records.
              </p>
              <p>
                If you have any question regarding our privacy policy, please{" "}
                <u>contact us</u>.
              </p>
              <p>&nbsp;</p>
              <p>
                <strong>VILLA TROPEZ RENTAL TERMS</strong>
              </p>
              <p>
                <strong>BEHAVIOUR &amp; NOISE POLICY</strong>
              </p>
              <p>
                <strong> </strong>
              </p>
              <ul>
                <li>
                  <strong>
                    <strong>
                      Casa Herta is a family holiday Villa &amp; Wellness
                      Retreat, in a peaceful rural setting; &amp; adheres by a
                      strict noise policy, with no parties allowed. Please limit
                      any noise &amp; music on outside areas to a quiet level
                      during the day &amp; evening. As of 10.30pm there is to be
                      no exterior noise on the pool terrace, balconies, gardens
                      etc; and limited noise levels from inside areas.
                    </strong>
                  </strong>
                  <strong>
                    {" "}
                    If there is continual excessive noise, all clients may be
                    asked to vacate the villa and the rental period will be
                    terminated immediately. The same applies to guests who act
                    maliciously toward staff / neighbours or do damage to the
                    property.{" "}
                  </strong>
                  <strong>
                    Pool terrace lighting and all lighting throughout the
                    grounds switches off nightly at 11pm, to maintain a serene
                    retreat feel, &amp; to respect the neighbours in this quiet
                    rural setting.{" "}
                  </strong>
                </li>
              </ul>
              <ul>
                <li>
                  This is a self-contained property, and does not have a
                  concierge service. The caretakers are on-site for the meet and
                  greet service on arrival to demonstrate the workings of the
                  amenities, and visit for a mid-week clean (or other maid
                  services as requested); and then throughout your stay they
                  should only be contacted for emergency requests only. There is
                  a comprehensive Welcome pack sent before arrival, and also
                  displayed at the Villa. It contains all information needed
                  about the Villa, its facilities, surrounding highlights &amp;
                  restaurant etc suggestions.
                </li>
              </ul>
              <ul>
                <li>
                  The person signing the contract is responsible for the correct
                  and decent behaviour of all members of the party. Should you
                  or a member of the party not behave in such a manner, the
                  Owner may use their absolute discretion to terminate the
                  holiday of the person(s) In this situation, the person(s)
                  concerned will be required to leave the accommodation. The
                  Owner will not have any further responsibility toward such
                  person(s) including any return travel arrangements.
                </li>
              </ul>
              <ul>
                <li>
                  No refunds will be made, nor payment of any expenses or
                  travel/accommodation costs incurred as a result of the
                  terminations outlined above.
                </li>
              </ul>
              <p>&nbsp;</p>
              <p>
                <strong>PAYMENT &amp; SECURITY DEPOSIT</strong>
              </p>
              <ul>
                <li>
                  <strong>
                    Bookings run from 4pm the day of arrival, to 10am the day of
                    departure. (No access onto the grounds or property can be
                    allowed prior or after these times)
                  </strong>
                </li>
              </ul>
              <ul>
                <li>
                  <strong>A </strong>
                  <strong>booking deposit of 30% of total rental rate</strong>
                  <strong>
                    {" "}
                    is payable; the booking is not confirmed until the payment
                    and the signed rental agreement form is received. If booking
                    within 9 wks of arrival, full payment is due.{" "}
                  </strong>
                  Dates cannot be held &amp; the booking is not confirmed until
                  BOTH the signed rental agreement &amp; payment is received.
                  Payment will be sent back immediately in the case of another
                  booking having been confirmed earlier by another party for the
                  same dates.
                </li>
              </ul>
              <ul>
                <li>
                  <strong>
                    Balance of rental payment is to be paid at the latest 9
                    weeks before arrival
                  </strong>
                  <strong>
                    . If the Client cancels 9 weeks or more before arrival the
                    deposit will be lost. If a Client cancels 9 weeks or less
                    before arrival, or the booking cancelled by the Owner due to
                    non-payment, the Owner is entitled to receive full rental
                    costs
                  </strong>
                </li>
              </ul>
              <ul>
                <li>
                  <strong>
                    The Owner has the right to re-let the property to other
                    parties if full payment is not received before 9 weeks. The
                    booking deposit{" "}
                  </strong>
                  may be returned in the event of cancellation more than 9 weeks
                  before arrival, providing the Villa is let for the same period
                  more than 9 weeks ahead of the scheduled booking. The Owner
                  has discretion over suitability of tenants.<strong> </strong>
                </li>
              </ul>
              <ul>
                <li>
                  <strong>An additional </strong>
                  <strong>security deposit of 1750 euros</strong>
                  <strong>
                    {" "}
                    is to be paid on top of the rental payment. This is to be
                    paid at the time of balance of rental, 9 weeks prior to
                    arrival. The security deposit will be returned two weeks
                    after departure, providing there are no damages to the
                    property or furniture, and everything is accounted for.
                    Costs of damages/losses/extra cleaning will be deducted from
                    the security deposit. It is suggested that you take out
                    holiday insurance that covers accidental damage (most
                    holiday insurance for holidays in rental accommodation cover
                    accidental damage).
                  </strong>
                </li>
              </ul>
              <ul>
                <li>
                  <strong>
                    In addition you will be responsible for the total costs of
                    damages sustained as a result of your stay, should they
                    exceed the amount of the security deposit.
                  </strong>{" "}
                  You will be responsible for any incidences / damages / losses
                  from a staff member you may have employed during your stay eg.
                  babysitter, chef.
                </li>
              </ul>
              <ul>
                <li>
                  <strong>
                    Change of bedding arrangements, extra guest fees, extra
                    linen requests, unpaid maid service fees etc, excess
                    cleaning fees on departure if left untidy etc, will be
                    deducted from the security deposit.{" "}
                  </strong>
                </li>
              </ul>
              <p>
                <strong> </strong>
              </p>
              <p>
                <strong>
                  ACCESS, GUEST NUMBERS, DURING THE STAY, DEPARTURE
                </strong>
              </p>
              <ul>
                <li>
                  Check-in is between{" "}
                  <strong>4pm &amp; 6pm on the arrival date</strong> (unless
                  special arrangements are agreed in advance).{" "}
                  <strong>Check-in time is not to be </strong>
                  <strong>before 4 pm &#8211; </strong>
                  <strong>
                    do not arrive for any reason before this time as you will
                    not be able to enter the property.
                  </strong>
                </li>
              </ul>
              <ul>
                <li>
                  <strong>Check out is 10am</strong>
                  <strong>
                    : The period for which you are renting the property is
                    stated on the first page of the Rental Agreement. By signing
                    this document, you agree that you and every member of your
                    party will vacate the property no later than 10am on your
                    Rental Agreement end date. You have no rights to extend your
                    stay for any reason without the express written permission
                    from the Owner. Additional expenses incurred from service
                    staff or otherwise will be deducted from the security
                    deposit, in the event of delayed departure.
                  </strong>
                  <strong> </strong>
                </li>
              </ul>
              <ul>
                <li>
                  The property &amp; grounds are to be left in a clean and tidy
                  manner.<strong> </strong>
                </li>
              </ul>
              <ul>
                <li>
                  <strong>
                    The villa accommodates a maximum of 14 guests. Advertised
                    rates are for 12 persons (or 8 guests during off-season).
                    For more than 12 guests staying at any time during high
                    season prior approval from the Owner is to be obtained, and
                    a fee of €700 euros per additional person per week will
                    apply (including infants, children).{" "}
                  </strong>
                  The full weekly sum will automatically be deducted from your
                  security deposit if extra persons have arrived during the
                  rental period<strong>. </strong>Only the persons stated on the
                  Rental Agreement may use the property unless otherwise agreed
                  in advance. The Owner has the right to terminate hire without
                  prior notice and without refund or compensation if the numbers
                  are exceeded without approval.<strong> </strong>
                </li>
              </ul>
              <ul>
                <li>
                  If you vacate the property for any reason before the departure
                  date, there is no entitlement to a refund
                </li>
              </ul>
              <ul>
                <li>
                  Please take care to use facilities correctly and not tamper
                  with settings eg. septic system as instructed, do not move
                  furniture, and do not alter any settings on timers or other
                  electrical settings. If there is a problem with any utility on
                  the property, an accidental breakage / damage / disconnection
                  to any item or mismanagement of utilities, please inform the
                  caretakers immediately to rectify, otherwise there will be
                  extra compensation charged for emergency repairs /
                  replacements on the changeover day.
                </li>
              </ul>
              <ul>
                <li>
                  <strong>
                    You are asked to leave the property in a clean and tidy
                    manner on departure. Any excessive cleaning &amp; utility
                    expenses above and beyond what is normally and reasonably
                    expected will be deducted from the security deposit.
                  </strong>
                </li>
              </ul>
              <ul>
                <li>
                  The Owner cannot accept liability for alteration, delay,
                  inaccessibility to / from the Villa or cancellation of your
                  Villa rental because of local flood, fire, sickness, weather
                  conditions, action at an airport or port by any governmental
                  or public authority, technical problems relating to transport,
                  airport regulations, temporary, technical, mechanical or
                  electrical breakdown with your accommodation, war, or threat
                  of war, riot or civil strife, terrorist activity (threatened
                  or actual) or other circumstances amounting to natural
                  disasters.
                </li>
              </ul>
              <ul>
                <li>
                  The Owner / tradespersons are able to have access to the
                  property at all times. There are utility services (septic
                  tanks, hot water systems, internet router etc) which need to
                  be serviced occasionally during the rental season. Being in a
                  rural location, occasionally there are temporary stoppages to
                  these &amp; public utilities such as water, gas and
                  electricity due to intermittent town services or adverse
                  weather. Tradesmen will be called in as soon as possible to
                  resolve any issues, though please bear in mind that
                  tradespeople may not work on weekends, &amp; are very busy
                  during the high season, so there may be a delay in resuming
                  services. The Owner cannot be held liable for any compensation
                  due to temporary problems such as these.<strong> </strong>
                </li>
              </ul>
              <ul>
                <li>
                  The Owner will not enter into any discussions, complaints or
                  be liable for any form of compensation pertaining to other
                  businesses or persons (therapists, chefs, transport or tour
                  providers, retreat teachers, etc) offering their services
                  recommended by or listed on the Casa Herta website or contact
                  lists. The Casa Herta Owner cannot be held responsible for any
                  misinformation, negligible duty, malpractice, incidents,
                  injuries or damages arising from the use of these referred
                  services, whether it be on-site on the Villa grounds or
                  off-site. All communications are to be directly between the
                  guest &amp; the service provider.
                </li>
              </ul>
              <p>
                <strong> </strong>
              </p>
              <p>
                <strong>INCLUSIONS</strong>
                <strong> </strong>
              </p>
              <ul>
                <li>
                  <strong>
                    Bed linen (except cot linen), 1 bath towel &amp; 1 pool
                    towel per guest is included, changed once a week, on
                    Saturdays.{" "}
                  </strong>{" "}
                  <strong>
                    Any extra linen &amp; towels requested / laundered will be
                    deducted from the security deposit.{" "}
                  </strong>
                  If you require more frequent changes, please request this at
                  the time of booking, for which there will be an additional
                  cost.
                </li>
              </ul>
              <ul>
                <li>
                  <strong>
                    A mid-week clean and Saturday clean (incl. final clean) is
                    included. Additional cleaning or maid services can be
                    arranged at additional cost.
                  </strong>
                  <strong> </strong>
                </li>
              </ul>
              <ul>
                <li>
                  <strong>Utilities are included, within reason</strong>
                </li>
              </ul>
              <ul>
                <li>
                  <strong>
                    Broadband Internet connection is included. There is Wi-Fi
                    access, though{" "}
                  </strong>
                  the Owner cannot be held liable for any signal shortages, or
                  technical problems preventing Wi-Fi access to not synchronise
                  with guests’ wireless devices.
                </li>
              </ul>
              <p>
                <strong> </strong>
              </p>
              <p>
                <strong>PROPERTY DESCRIPTION &amp; ACCESS</strong>
              </p>
              <ul>
                <li>
                  Borders of the property are fenced, though certain areas at
                  the peripheries are unlandscaped, in their natural state,
                  keeping in line with surrounding forest and farmland
                </li>
              </ul>
              <ul>
                <li>
                  The Owner reserves the right to make modifications to the
                  property specification that are considered necessary in light
                  of operating requirements. In the interest of continual
                  improvement, the Owners reserve the right to alter furniture,
                  amenities &amp; facilities, either advertised or previously
                  available, without prior notice.{" "}
                  <strong>
                    Furniture, furnishings &amp; amenities may not be exactly
                    the same as in photos / prior rentals. Due to breakage /
                    wear and tear each year, some pieces may have been replaced,
                    with the replacement being as close as possible to the
                    original style.
                  </strong>
                </li>
              </ul>
              <p>&nbsp;</p>
              <p>
                <strong>PETS &amp; SMOKING</strong>
              </p>
              <ul>
                <li>
                  There is a no-smoking policy inside the Villa or apartment.
                </li>
                <li>
                  Casa Herta may approve well-behaved small pets, please enquire
                  for approval at time of booking. Any extra cleaning fees or
                  damages to grounds or furniture by pets will be taken from the
                  security deposit. Please clean up after your dog in the
                  grounds on a daily basis.
                </li>
              </ul>
              <p>&nbsp;</p>
              <p>
                <strong>SWIMMING POOL</strong>
              </p>
              <ul>
                <li>
                  Please note that swimming pools carry their own inherent
                  risks. Upon arrival at the property you and all members of
                  your party must take time to familiarise yourselves with the
                  location, layout, depth and how to activate the alarm of the
                  swimming pool. Young children must not be allowed to wander
                  unaccompanied in the grounds, and{" "}
                  <strong>
                    must be closely supervised at all times, it is your sole
                    responsibility.
                  </strong>
                </li>
                <li>
                  <strong>
                    There is a pool alarm fitted for you to activate:
                    instructions are provided. It is your responsibility to
                    familiarise yourself with the instructions for use, and to
                    activate the alarm. The alarm is to be active at all times
                    if any of your party cannot swim. The Owner / caretakers
                    cannot be held liable for accidents in and around the pool
                    area, and accepts no responsibility for the status of the
                    pool alarm at any time.
                  </strong>
                </li>
                <li>
                  The pool may not be available all year round. If your rental
                  period is outside of June &#8211; Sept please check with the
                  Owner that the pool will be open for use.<strong> </strong>
                </li>
              </ul>
              <p>&nbsp;</p>
              <p>
                <strong>SECURITY, VALUABLES &amp; LIABILITY</strong>
              </p>
              <ul>
                <li>
                  <strong>
                    The health and safety of you and all of your party is your
                    own responsibility.{" "}
                  </strong>
                  We suggest you take out a comprehensive Travel Insurance
                  policy for all guests for all sections of your travel and
                  stay.<strong> </strong>
                </li>
              </ul>
              <ul>
                <li>
                  <strong>
                    The Owner / caretaker accepts no responsibility or liability
                    for accidents or injuries sustained whilst on or off the
                    property, for the duration of your stay. As with any large
                    property, with unfenced pools / fountains, unlandscaped /
                    hilly areas, open steep staircases, &amp; gym equipment;
                    there are certain hazards and dangers: it is your
                    responsibility to ensure the welfare of yourselves and to
                    supervise children at all times throughout the property.{" "}
                  </strong>
                </li>
                <li>
                  <strong>
                    The Owner / caretaker cannot be held liable for loss or
                    theft of personal belongings taken from the property or
                    elsewhere
                  </strong>
                  <strong>. </strong>Any valuables left at the property are left
                  at your own risk. The Owner cannot be held responsible for any
                  loss. The safe is to be used and proper care be taken against
                  theft and burglary. It is your responsibility to ensure all
                  doors and windows are closed and locked when leaving the
                  property, or when not inside the property, ie. by the poolside
                  / in the grounds. If theft or damage to the property occurs
                  through negligence on your part the owner is entitled to seek
                  compensation from you.
                </li>
              </ul>
              <h2>
                <strong>
                  <em> </em>
                </strong>
              </h2>
              <h2>
                <strong>
                  Contrat de Location<em> &#8211; version français</em>
                </strong>
              </h2>
              <p>
                <strong>PAIEMENT &amp; DÉPÔT DE SÉCURITÉ</strong>
              </p>
              <p>
                &#8211; Réservations à partir de 16 heures le jour de
                l&#8217;arrivée jusqu’à 10 heures le jour du départ.
              </p>
              <p>
                &#8211; Un dépôt de réservation de 30% du montant de la location
                total est payable; La réservation n&#8217;est pas confirmée
                jusqu&#8217;à ce que le dépôt et le contrat de location signé
                ont été reçu. Si la réservation est effectuée dans les 9
                semaines avant l&#8217;arrivée, le paiement intégral est dû.
              </p>
              <p>
                &#8211; Les prix et la disponibilité sont sujets à changement à
                tout moment jusqu&#8217;à confirmation de la réservation. Les
                dates ne peuvent être retenues et la réservation n&#8217;est pas
                confirmée tant que le contrat de location signé et le paiement
                ont été reçu. Les fonds seront renvoyés immédiatement dans le
                cas d&#8217;une autre réservation confirmée plus tôt pour les
                mêmes dates. Le solde du paiement de la location doit être payé
                au plus tard 9 semaines avant l&#8217;arrivée. Si le client
                annule 9 semaines ou plus avant l&#8217;arrivée, le dépôt sera
                perdu. Si un Client annule 9 semaines ou moins avant
                l&#8217;arrivée, ou la réservation est annulée par le
                Propriétaire en raison du non-paiement, le Propriétaire a le
                droit de recevoir les frais de location complets.
              </p>
              <p>
                &#8211; Le Propriétaire a le droit de restituer le bien à
                d&#8217;autres parties si le paiement complet n&#8217;est pas
                reçu avant 9 semaines.
              </p>
              <p>
                &#8211; Un dépôt de garantie de 1750 euros doit être payé en
                plus du paiement de la location. Cela doit être payé au moment
                du règlement du solde 9 semaines avant l&#8217;arrivée. Le dépôt
                de garantie sera retourné deux semaines après le départ, à
                condition qu&#8217;il n&#8217;y ait eu aucun dommage dans la
                propriété ou le mobilier. Les frais de dommages / pertes /
                nettoyage supplémentaire seront déduit de la caution. Il est
                suggéré que vous preniez une assurance de vacances qui couvre
                les dommages accidentels.
              </p>
              <p>
                &#8211; En outre, vous serez responsable du coût total des
                dommages subis à la suite de votre séjour au cas ou ils
                dépassent le montant du dépôt de garantie. Vous serez
                responsable de tout incident / dommages / pertes causes par un
                membre du personnel que vous avez pu employer pendant votre
                séjour, par exemple. Baby-sitter, chef,etc.
              </p>
              <p>
                &#8211; Les changements de dispositions de literie, frais
                supplémentaires pour les invités, les demandes de linge
                supplémentaire, les frais de service de nettoyage non payés etc,
                les frais de nettoyage supplémentaire au départ si nécessaire,
                etc, seront déduits de la caution.
              </p>
              <p>
                <strong>COMPORTEMENT &amp; BRUIT</strong>
              </p>
              <p>
                &#8211; Casa Herta est une résidence familiale située dans un
                cadre rural et paisible qui adhère à une stricte politique de
                bruit. Veuillez limiter tout bruit et musique a l’extérieur à un
                niveau calme pendant la journée. A partir de 22h, plus de bruit
                extérieur sur la terrasse de la piscine, les balcons, les
                jardins etc ainsi qu’un niveau de bruit minimal a
                l&#8217;intérieur de la maison afin de respecter le voisinage.
                En cas de bruit excessif continu, tous les clients peuvent être
                forcés de quitter la villa et la location sera résiliée
                immédiatement. Il en va de même pour les clients qui agissent de
                façon malveillante envers le personnel ou les voisins ou qui
                endommagent la propriété.
              </p>
              <p>
                &#8211; La personne qui signe le contrat est responsable du
                comportement correct et décent de tous les membres du groupe. Si
                vous ou un membre du groupe ne se comporte pas de cette manière,
                le propriétaire peut mettre fin aux vacances de la personne
                concernée. Dans ce cas, la ou les personnes concernées devront
                quitter la propriété. Le propriétaire n&#8217;assume aucune
                autre responsabilité à l&#8217;égard de ces personnes, y compris
                les arrangements de voyage de retour.
              </p>
              <p>
                &#8211; Toute forme d&#8217;événement doit avoir
                l&#8217;approbation préalable du propriétaire. Seules les
                personnes mentionnées dans le contrat de location peuvent
                utiliser la propriété, sauf accord préalable. Le propriétaire et
                le personnel ont le droit de résilier la location sans préavis
                et sans remboursement ou compensation si le nombre de personne
                est dépassé sans approbation.
              </p>
              <p>
                &#8211; Aucun remboursement ne sera effectué, ni paiement des
                frais ou frais de voyage / hébergement engagés à la suite des
                résiliations décrites ci-dessus.
              </p>
              <p>
                <strong>
                  ACCÈS, NUMÉROS DE CONTACT, PENDANT LE SÉJOUR, DÉPART
                </strong>
              </p>
              <p>
                &#8211; Check-in entre 16h et 18h à la date d&#8217;arrivée (à
                moins d’un arrangement spécial convenu à l&#8217;avance).
                L’heure d&#8217;arrivée ne doit pas être avant 16 heures car
                vous ne serez pas en mesure d&#8217;entrer dans la propriété.
              </p>
              <p>
                &#8211; Check out a 10am: La période pour laquelle vous louez le
                bien est indiquée sur la première page du contrat de location.
                En signant ce document, vous acceptez que vous et tous les
                membres de votre groupe quitterez la propriété au plus tard à 10
                h à la date de fin de votre contrat de location. Vous
                n&#8217;avez aucun droit de prolonger votre séjour pour quelque
                raison que ce soit sans l&#8217;autorisation écrite du
                propriétaire. Les frais supplémentaires engagés par le personnel
                de service ou autre seront déduits du dépôt de garantie en cas
                de départ différé.
              </p>
              <p>
                &#8211; Pour plus de 12 personnes séjournant à un moment donné,
                une autorisation préalable du propriétaire doit être obtenue, et
                des frais de 700 € par personne supplémentaire par semaine, ou
                une partie de celui-ci, s&#8217;appliqueront (y compris les
                enfants), jusqu&#8217;à concurrence de 15 personnes. Seules les
                personnes mentionnées dans le contrat de location peuvent
                utiliser la propriété, sauf accord préalable. Le Propriétaire a
                le droit de résilier la location sans préavis et sans
                remboursement ou compensation si ces conditions ne sont pas
                respectées sans accord préalable.
              </p>
              <p>
                &#8211; Si vous quittez la propriété pour une raison quelconque
                avant la date de départ, il n&#8217;y a aucun droit à un
                remboursement.
              </p>
              <p>
                &#8211; Il s&#8217;agit d&#8217;une propriété autonome qui
                n&#8217;offre pas de service de concierge. Vous serez accueilli
                à votre arrivée entre 4pm et 6pm et il y aura un nettoyage de la
                villa en milieu de semaine. Le gardien pourra être contacte en
                cas d’urgence tout au long de votre séjour. Un pack de bienvenue
                complet vous sera envoyé avant l&#8217;arrivée et également
                affiché à la Villa. Il contient toutes les informations
                nécessaires pour votre séjour.
              </p>
              <p>
                &#8211; Veillez à utiliser correctement les installations et à
                ne pas altérer les réglages, par ex. Système septique, ne pas
                déplacer les meubles et ne modifiez aucun réglage sur les
                minuteries ou autres réglages électriques. S&#8217;il y a un
                problème sur la propriété, un bris accidentel / dommage /
                déconnexion internet,etc, veuillez informer les gardiens
                immédiatement afin de rectifier le problème. Tout manquement
                risque d’entrainer la facturation de frais supplémentaires pour
                les réparations d&#8217;urgence / remplacements lors de votre
                départ.
              </p>
              <p>
                &#8211; Il vous est demandé de laisser la propriété et les
                terrains tels que vous les avez trouvé à votre arrivée. Tout
                frais excessif de nettoyage au-delà de ce qui est normale et
                raisonnablement attendu sera déduit du dépôt de garantie.
              </p>
              <p>
                &#8211; Le propriétaire ne peut pas être tenu pour responsable
                de la modification, du retard, de l&#8217;inaccessibilité de la
                Villa ou de l&#8217;annulation de votre location de Villa en
                raison d&#8217;inondations locales, d&#8217;incendie, de
                maladie, de conditions météorologiques, d&#8217;action à un
                aéroport ou un port par toute autorité gouvernementale ou
                publique, La guerre, la menace de guerre, les émeutes ou les
                troubles civils, les activités terroristes (menacées ou réelles)
                ou toute autre circonstance résultant de catastrophes
                naturelles.
              </p>
              <p>
                &#8211; Le propriétaire / gardiens / artisans sont en mesure
                d’accéder la propriété a tout moment pour l’entretien générale
                de la propriété. Il y a des services publics (fosses septiques,
                systèmes d&#8217;eau chaude, climatisation, électricité etc) qui
                doivent être entretenus de temps en temps pendant la saison de
                location. Étant en zone rurale, il y a parfois des arrêts
                temporaires de certains services tels que l&#8217;eau,
                l’internet et l&#8217;électricité en raison de greves ou de
                conditions météorologiques défavorables. Un réparateur sera
                appelé dès que possible pour résoudre les problèmes, mais
                s&#8217;il vous plaît garder à l&#8217;esprit que certains
                réparateurs ne travaillent pas le week-end ou sont très occupés
                pendant la haute saison, causant un retard dans la reprise des
                services. Le propriétaire ne peut être tenu responsable pour
                tout problèmes temporaires tels que ceux-ci.
              </p>
              <p>
                &#8211; Le Propriétaire ne participera à aucune discussion,
                plainte ou ne sera pas responsable pour toute forme d’indemnités
                liée à d&#8217;autres entreprises ou personnes (thérapeutes,
                chefs, transporteurs ou organisateurs de voyages, etc.) offrant
                leurs services recommandés parCasa Herta. Le Propriétaire de
                Casa Herta ne peut être tenu responsable de toute
                désinformation, négligeable, incident, blessure ou dommage
                résultant de l&#8217;utilisation de ces services référencés, que
                ce soit sur la propriété ou hors site. Toutes les communications
                doivent être directement entre le client et le fournisseur de
                services.
              </p>
              <p>&nbsp;</p>
              <p>
                <strong>INCLUSIONS</strong>
              </p>
              <p>
                &#8211; Linge de lit (sauf linge de lit bébé), 1 serviette de
                bain et 1 serviette de piscine par personne est inclus, changé
                une fois par semaine le samedi. Tout linge supplémentaire et les
                serviettes demandées / blanchis seront déduits de la caution.
              </p>
              <p>
                &#8211; Un nettoyage à la mi-semaine et fin de semaine est
                inclus. Les charges d’eau, électricité, gaz, internet sont
                incluses dans la mesure ou ils restent raisonnables.
              </p>
              <p>
                &#8211; Une connexion Wi-Fi est incluse. Le propriétaire ne peut
                être tenu pour responsable des pannes de signal ou des problèmes
                techniques empêchant la connexion au Wi-Fi.
              </p>
              <p>&nbsp;</p>
              <p>
                <strong>DESCRIPTION DE LA PROPRIÉTÉ ET ACCÈS</strong>
              </p>
              <p>
                &#8211; Le propriétaire se réserve le droit d&#8217;apporter des
                modifications à la propriété qui sont jugées nécessaires compte
                tenu des exigences d&#8217;exploitation. Dans l&#8217;intérêt de
                l&#8217;amélioration continue, les propriétaires se réservent le
                droit de modifier le mobilier, les équipements et les
                installations, annoncés ou précédemment disponibles, sans
                préavis. Les meubles et les appareils électriques peuvent ne pas
                être exactement les mêmes que dans les photos / locations
                antérieures. En raison de la rupture / usure chaque année,
                certaines pièces ont peut-être été remplacées, le remplacement
                étant le plus proche possible du style original.
              </p>
              <p>
                &#8211; La propriété est entièrement clôturée et accessible par
                2 portails électriques. Une partie de la propriété est occupée
                par une foret de chênes avec certaines zones laissées en friche.
              </p>
              <p>
                &#8211; L&#8217;éclairage de la terrasse, de la piscine et du
                jardin s&#8217;éteint toutes les soirs à 23h afin de respecter
                les voisins dans ce cadre rural et calme.
              </p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>
                <strong>ANIMAUX ET FUMEURS</strong>
              </p>
              <p>
                &#8211; La villa ainsi que l’appartement sont totalement
                non-fumeur. Casa Herta peut autoriser les animaux domestiques.
                Merci de vous renseigner au moment de la réservation. Tout frais
                de nettoyage supplémentaire ou réparation de dommages par des
                animaux de compagnie seront retirés du dépôt de garantie.
              </p>
              <p>&nbsp;</p>
              <p>
                <strong>PISCINE</strong>
              </p>
              <p>
                &#8211; Veuillez noter que les piscines comportent des risques
                inhérents. À votre arrivée à la propriété vous et tous les
                membres de votre groupe doivent prendre le temps de se
                familiariser avec l&#8217;emplacement, la disposition, la
                profondeur et comment activer l&#8217;alarme de la piscine. Les
                jeunes enfants ne doivent pas être autorisés à errer sans être
                accompagnés dans les lieux et doivent être étroitement
                surveillés en tout temps, c&#8217;est votre seule
                responsabilité.
              </p>
              <p>
                &#8211; Une alarme de piscine est installée et les instructions
                seront fournies des votre arrivée. Il vous incombe de vous
                familiariser avec les instructions d&#8217;utilisation et
                d&#8217;activer l&#8217;alarme. L&#8217;alarme doit être active
                en tout temps si l&#8217;un des membres de votre groupe ne peut
                pas nager. Le propriétaire / gardien ne peut être tenu pour
                responsable des accidents dans et autour de la zone de la
                piscine, et n&#8217;accepte aucune responsabilité pour le statut
                de l&#8217;alarme de piscine à tout moment.
              </p>
              <p>
                &#8211; La piscine n’est pas disponible toute l&#8217;année. Si
                votre période de location est en dehors de Juin &#8211; Sept
                veuillez vérifier avec le propriétaire que la piscine sera
                ouverte.
              </p>
              <p>&nbsp;</p>
              <p>
                <strong>SÉCURITÉ, OBJETS DE VALEUR ET RESPONSABILITÉ</strong>
              </p>
              <p>
                &#8211; Votre sécurité ainsi que celle de votre groupe est de
                votre propre responsabilité. Nous vous suggérons de souscrire
                une assurance voyage complète pour tous les invités pour toutes
                les sections de votre voyage et votre séjour.
              </p>
              <p>
                &#8211; Le propriétaire / gardien n&#8217;accepte aucune
                responsabilité pour les accidents ou les blessures subis dans ou
                hors de la propriété pour la durée de votre séjour. Comme avec
                toute grande propriété, avec des piscines non clôturées,
                fontaines, zones irrégulières, des escaliers raides, matériel de
                gymnastique, il y a certains dangers. Il est de votre
                responsabilité d&#8217;assurer votre bien-être et de superviser
                les enfants en tout temps dans toute la propriété.
              </p>
              <p>
                &#8211; Le propriétaire / gardien ne peut être tenu pour
                responsable de la perte ou du vol de biens personnels dans la
                propriété ou ailleurs. Tout objet de valeur laissé à la
                propriété est laissé à vos propres risques. Le propriétaire ne
                peut être tenu responsable de toute perte. Le coffre-fort doit
                être utilisé et des soins appropriés doivent être pris contre le
                vol et le cambriolage. Il est de votre responsabilité de
                s&#8217;assurer que toutes les portes et les fenêtres sont
                fermées et verrouillées lorsque vous quittez la propriété, ou
                quand vous n’êtes pas à l&#8217;intérieur de la propriété,
                c&#8217;est à dire près de la piscine ou dans le domaine. En cas
                de vol ou dommages à la propriété suite a des négligences de
                votre part, le propriétaire a le droit de demander une
                indemnisation de votre part.
              </p>
              <h2></h2>
              <h2>
                <strong>Additional Terms for Retreat Organisers</strong> (full
                rental terms will be sent at time of booking for signing)
              </h2>
              <p>
                &#8211; Retreat organisers/ facilitators and teachers must have
                teacher liability insurance covering them for teaching at this
                international venue. Please contact your insurer for them to add
                your upcoming retreat onto your existing policy.
              </p>
              <p>
                &#8211; All guests / students /facilitators staying or attending
                a retreat organised by an independent renter / retreat organiser
                &#8211; travel insurance is a condition of attendance for all.
                Casa Herta does not have any interaction or liability towards
                any guest staying prior, for the duration, or after an event
                organised by an independent renter / retreat organiser. The
                renter/ retreat organiser has the responsibility to ensure that
                each retreat guest obtains travel insurance prior to attending a
                retreat. It is recommended that guests/students and teachers
                research their insurance requirements and choose what type of
                cover is relevant to their travel, stay and participation in the
                event. There are policies available which include: “Cancel for
                any Reason” which are recommended for the organiser to take out.
                (Please be aware that yoga retreat trips are not regarded as
                ‘tours’ under most Insurers’ policies, hence the insured
                guest/facilitator may not be covered for cancellation of
                flights, deposits etc, or in the event of cancellation due to
                insufficient numbers etc.)
              </p>
              <p>
                Casa Herta is a self-contained property with no staff on-site,
                though you are welcome to book further cleaning / kitchen help
                services at extra cost. We suggest organisers book a night
                before retreat start, to arrive and settle in and ensure
                adequate set up time. There is a meet and greet by the
                caretakers on arrival, end-of-stay clean, and one mid-stay clean
                for 7+ night stays, unless otherwise quoted. Staff visit on-site
                during these times only &#8211; it is a self-contained property
                without staff at all other times.
              </p>
              <p>&nbsp;</p>
              <p>
                <strong>
                  DEPOSIT, PAYMENT AND CANCELLATION POLICY &#8211; RETREAT
                  RENTAL
                </strong>
              </p>
              <ul>
                <li>
                  Deposit: 30% of villa rental rate required to confirm booking,
                  with the balance due 4 weeks prior (July-Aug bookings, 9 wks
                  prior). Full rental amount due if booking within 6 weeks of
                  arrival.
                </li>
              </ul>
              <p>
                <strong>
                  The Deposit is non-refundable, and non-transferrable to any
                  other dates. In the event of multiple week bookings – pro-rata
                  deposit amounts cannot be transferred to the other dates still
                  held by the same party
                </strong>
                . <strong>A </strong>
                <strong>booking deposit of 30% of total rental rate</strong>
                <strong>
                  {" "}
                  is payable; the booking is not confirmed until the deposit and
                  a signed rental agreement form is received. If booking within
                  6 wks of arrival, full payment is due.{" "}
                </strong>
              </p>
              <ul>
                <li>
                  <strong>
                    Prices and availability are subject to change at any time.
                    Dates cannot be held &amp; the booking is not confirmed
                    until BOTH the signed rental agreement &amp; payment
                  </strong>{" "}
                  is received. Payment will be sent back immediately in the case
                  of another booking having been confirmed earlier by another
                  party for the same dates.<strong> </strong>
                </li>
              </ul>
              <ul>
                <li>
                  <strong>
                    Balance of rental payment is to be paid at the latest 4
                    weeks before arrival (or 9 weeks for July/Aug dates)
                  </strong>
                  <strong>
                    . If the renter cancels 4 weeks or more before arrival the
                    deposit will be lost. If the renter cancels within 4 weeks
                    of arrival, or the booking cancelled by the Owner due to
                    non-payment, the Owner is entitled to receive full rental
                    costs.
                  </strong>
                  <strong> </strong>
                </li>
              </ul>
              <ul>
                <li>
                  <strong>
                    The Owner has the right to re-let the property to other
                    parties if full payment is not received before 4 weeks. The
                    booking deposit{" "}
                  </strong>
                  may only be returned in the event of cancellation more than 4
                  weeks before arrival, providing the Villa is let for the same
                  period more than 4 weeks ahead of the scheduled booking, for
                  the same rental price owed. The Owner has discretion over
                  suitability of tenants, and the last-minute discounted price
                  advertised to fill the dates.
                </li>
              </ul>
              <ul>
                <li>
                  <strong>An additional </strong>
                  <strong>security deposit of €1000 </strong>
                  <strong>
                    is to be paid on top of the rental payment. This is to be
                    paid at the time of balance of rental, 4 weeks prior to
                    arrival. The security deposit will be returned two weeks
                    after departure, providing there are no damages to the
                    property or furniture, and everything is accounted for.
                    Costs of damages/losses/extra cleaning will be deducted from
                    the security deposit. It is suggested that you take out
                    insurance that covers accidental damage. In addition you
                    will be responsible for the total costs of damages sustained
                    as a result of your stay, should they exceed the amount of
                    the security deposit.
                  </strong>
                </li>
              </ul>
              <ul>
                <li>
                  <strong>
                    Any extra unpaid costs at time of departure eg. extra linen
                    requests, unpaid maid service fees, catering costs etc, will
                    be deducted from the security deposit.{" "}
                  </strong>
                </li>
              </ul>
              <p>
                <strong> </strong>
              </p>
              <p>
                <strong>RETREAT RENTAL RATE EXCLUSIONS</strong>
              </p>
              <p>The rental price does not include:</p>
              <p>
                &#8211; Daily cleaning or linen changes (one mid-stay clean is
                offered for stays of 4 nights or longer)
              </p>
              <p>&#8211; Flights</p>
              <p>
                &#8211; Travel Insurance or any form of Professional
                Liability/Practitioner insurance for the organiser/teachers
              </p>
              <p>
                &#8211; Airport transfers or local transportation (eg to St
                Antonio)
              </p>
              <p>&#8211; Excursions outside the villa</p>
              <p>&#8211; Massages or treatments</p>
              <p>
                &#8211; Other travel related expenses<strong> </strong>
              </p>
              <p>&nbsp;</p>
              <ul>
                <li>
                  Rates quoted are for rental of Villa &amp; retreat facilities
                  only. Airfares, travel insurance, transportation, transfers,
                  excursions, catering of meals, extra cleaning or linen
                  services are extra, and provided by external services, to be
                  booked through them.
                </li>
              </ul>
              <p>&nbsp;</p>
              <p>
                <strong>ADDITIONAL OPTIONAL SERVICES</strong> (Approximate
                pricing only. All service providers are separate businesses and
                are to be contacted directly for availability, pricing, booking
                communications and payment)
              </p>
              <ul>
                <li>
                  Daily maid service available for making up of bedrooms/ common
                  areas: approx €23 p/hr, minimum €50 per visit. Mid-week linen
                  change available (fees apply).
                </li>
              </ul>
              <p>&nbsp;</p>
              <p>
                <strong>
                  INSURANCE &#8211; <strong>RETREAT RENTAL</strong>
                </strong>
              </p>
              <p>
                &#8211; Retreat organisers/ facilitators and teachers must have
                teacher/professional liability insurance that covers them for
                teaching internationally. Please contact your insurer for them
                to add your upcoming retreat onto your existing policy.
              </p>
              <p>
                &#8211; For all guests / students and facilitators staying at
                Casa Herta, travel insurance is a condition of attendance for
                all. The Retreat Organiser has the responsibility to ensure that
                each retreat guest obtains travel insurance prior to attending a
                retreat. It is recommended that guests/students and teachers
                research their insurance requirements and choose what type of
                cover is relevant to their travel, stay and participation in the
                event, and to include personal injury and belongings whilst on
                premises and during travel, and to cover trip cancellation etc.
                There are policies available which include: “Cancel for any
                Reason” which are recommended for the Organiser to take out.
                (Please be aware that yoga retreat trips are not regarded as
                ‘tours’ under most Insurers’ policies, hence the insured
                guest/facilitator may not be covered for cancellation of
                flights, deposits etc, or in the event of cancellation).
              </p>
              <p>
                <strong> </strong>
              </p>
              <p>
                <strong>
                  ACCESS, GUEST NUMBERS, DURING THE STAY, DEPARTURE
                </strong>
              </p>
              <p>
                <strong> </strong>
              </p>
              <ul>
                <li>
                  Check-in is between{" "}
                  <strong>4pm &amp; 6pm on the arrival date</strong> (unless
                  special arrangements are agreed in advance).{" "}
                  <strong>Check-in time is not to be </strong>
                  <strong>before 4 pm &#8211; </strong>
                  <strong>
                    do not arrive for any reason before this time as you will
                    not be able to enter the property. Casa Herta runs on a
                    tight schedule of same-day changeovers and cannot authorise
                    early arrivals / luggage dropoffs or late departures from
                    the property or grounds, unless specifically agreed in
                    advance.
                  </strong>
                  <strong> </strong>
                </li>
              </ul>
              <ul>
                <li>
                  <strong>Check out is 10am</strong>
                  <strong>
                    : The period for which you are renting the property is
                    stated on the first page of the Rental Agreement. By signing
                    this document, you agree that you and every member of your
                    party will vacate the property no later than 10am on your
                    Rental Agreement end date. You have no rights to extend your
                    stay for any reason without the express written permission
                    from the Owner. Additional expenses incurred from service
                    staff or otherwise will be deducted from the security
                    deposit, in the event of delayed departure.
                  </strong>
                </li>
              </ul>
              <ul>
                <li>
                  The Owner has the right to terminate hire without prior notice
                  and without refund or compensation if the numbers of guests
                  are exceeded without approval.
                </li>
              </ul>
              <ul>
                <li>
                  If you vacate the property for any reason before the departure
                  date, there is no entitlement to a refund.
                </li>
              </ul>
              <ul>
                <li>
                  Please take care to use facilities correctly and not tamper
                  with settings eg. septic system as instructed, do not move
                  furniture, and do not alter any settings on timers or other
                  electrical settings. If there is a problem with any utility on
                  the property, an accidental breakage / damage / disconnection
                  to any item or mismanagement of utilities, please inform the
                  caretakers immediately to rectify, otherwise there will be
                  extra compensation charged for emergency repairs /
                  replacements on the changeover day.<strong> </strong>
                </li>
              </ul>
              <ul>
                <li>
                  <strong>
                    You are asked to leave the property and grounds in a clean
                    and tidy manner on departure. Any excessive cleaning &amp;
                    utility expenses above and beyond what is normally and
                    reasonably expected will be deducted from the security
                    deposit.
                  </strong>
                </li>
              </ul>
              <ul>
                <li>
                  Upon departure from the property it is the client’s
                  responsibility to ensure that all keys and remote controls are
                  left at the property. An administration charge and replacement
                  value of lost items will be deducted from the security deposit
                  in this incidence.
                </li>
              </ul>
              <ul>
                <li>
                  The Owner cannot accept liability for alteration, delay,
                  inaccessibility to / from the Villa or cancellation of your
                  Villa rental because of local flood, fire, sickness, weather
                  conditions, action at an airport or port by any governmental
                  or public authority, technical problems relating to transport,
                  airport regulations, temporary, technical, mechanical or
                  electrical breakdown with your accommodation, war, or threat
                  of war, riot or civil strife, terrorist activity (threatened
                  or actual) or other circumstances amounting to natural
                  disasters.
                </li>
              </ul>
              <ul>
                <li>
                  The Owner / caretakers / tradespersons are able to have access
                  to the property at all times for general property and grounds
                  maintenance. There are utility services (septic tanks, hot
                  water systems, air-conditioning, electrics etc) which need to
                  be serviced occasionally during the rental season. Being in a
                  rural location, occasionally there are temporary stoppages to
                  these &amp; public utilities such as water, gas and
                  electricity due to intermittent town services or adverse
                  weather. Tradesmen will be called in as soon as possible to
                  resolve any issues, though please bear in mind that
                  tradespeople may not work on weekends, &amp; are very busy
                  during the high season, so there may be a delay in resuming
                  services. The Owner cannot be held liable for any compensation
                  due to temporary problems such as these.
                </li>
              </ul>
              <ul>
                <li>
                  The Owner will not enter into any discussions, complaints or
                  be liable for any form of compensation pertaining to other
                  businesses or persons (therapists, caterers, chefs, transport
                  or tour providers, retreat teachers, etc) offering their
                  services recommended by or listed on the Casa Herta website or
                  contact lists. The Casa Herta Owner cannot be held responsible
                  for any misinformation, negligible duty, malpractice,
                  incidents, injuries or damages arising from the use of these
                  referred services, whether it be on-site on the Villa grounds
                  or off-site. All communications are to be directly between the
                  guest &amp; the service provider.
                </li>
              </ul>
              <p>
                <strong> </strong>
              </p>
              <p>
                <strong>INCLUSIONS</strong>
              </p>
              <ul>
                <li>
                  Bed linen (except cot linen), 1 bath towel &amp; 1 pool towel
                  per guest is included, changed once a week. Any extra linen
                  &amp; towels requested / laundered will be deducted from the
                  security deposit. Additional cleaning, linen or maid services
                  can be arranged for a small additional cost.
                </li>
                <li>
                  A mid-week clean for stays 4 nights or longer and clean on
                  departure is included.
                </li>
                <li>Utilities are included, within reason.</li>
                <li>
                  Wi-Fi internet is included, though the Owner cannot be held
                  liable for any signal shortages, or technical problems
                  preventing Wi-Fi access to not synchronise with guests’
                  wireless devices.
                </li>
              </ul>
              <p>
                <strong> </strong>
              </p>
              <p>
                <strong>PROPERTY DESCRIPTION &amp; ACCESS</strong>
              </p>
              <ul>
                <li>
                  The Owner reserves the right to make modifications to the
                  property specification that are considered necessary in light
                  of operating requirements. In the interest of continual
                  improvement, the Owners reserve the right to alter furniture,
                  amenities &amp; facilities, either advertised or previously
                  available, without prior notice. Furnishings &amp; amenities
                  may not be exactly the same as in photos / prior rentals. Due
                  to breakage / wear and tear each year, some pieces may have
                  been replaced, with the replacement being as close as possible
                  to the original style.
                </li>
                <li>
                  <strong>
                    Poolterrace lights &amp; lighting of the grounds{" "}
                  </strong>
                  <strong>switch off at 11pm </strong>
                  <strong>
                    to respect the neighbours &amp; quiet rural setting.
                  </strong>
                </li>
              </ul>
              <p>
                <strong> </strong>
              </p>
              <p>
                <strong> </strong>
                <strong>BEHAVIOUR &amp; NOISE POLICY</strong>
              </p>
              <ul>
                <li>
                  <strong>
                    Casa Herta is a family holiday Villa &amp; Wellness Retreat,
                    in a peaceful rural setting; &amp; adheres by a strict noise
                    policy, with no parties. Please limit any noise &amp; music
                    on outside areas to a{" "}
                  </strong>
                  <strong>quiet level during the day</strong>
                  <strong>. As of </strong>
                  <strong>10pm</strong>
                  <strong>
                    {" "}
                    there is to be no exterior noise on the pool terrace,
                    balconies, gardens etc; and limited quiet noise levels from
                    inside areas, to respect the surrounding neighbours. If
                    there is continual excessive noise, all clients may be asked
                    to vacate the villa and the rental period will be terminated
                    immediately. The same applies to guests who act maliciously
                    toward staff / neighbours or do damage to the property.{" "}
                  </strong>
                </li>
                <li>
                  The person signing the contract is responsible for the correct
                  and decent behaviour of all members of the party. Should you
                  or a member of the party not behave in such a manner, the
                  Owner may use their absolute discretion to terminate the
                  holiday of the person(s) In this situation, the person(s)
                  concerned will be required to leave the accommodation. The
                  Owner will not have any further responsibility toward such
                  person(s) including any return travel arrangements.
                </li>
                <li>
                  <strong>
                    Any form of group event is to have prior approval from the
                    Owner.{" "}
                  </strong>
                  Only the persons stated on the Rental Agreement may use the
                  property unless otherwise agreed in advance. The Owner &amp;
                  staff have the right to terminate hire without prior notice
                  &amp; without refund/compensation if numbers are exceeded
                  without approval.
                </li>
                <li>
                  No refunds will be made, nor payment of any expenses or
                  travel/accommodation costs incurred as a result of the
                  terminations outlined above.
                </li>
                <li>
                  There is a no-smoking policy inside the Villa or apartment.
                  Casa Herta may approve well-behaved small pets, please enquire
                  for approval at time of booking. Any extra cleaning fees or
                  damages to grounds or furniture by pets will be taken from the
                  security deposit.
                </li>
              </ul>
              <p>&nbsp;</p>
              <p>
                <strong>SWIMMING POOL</strong>
              </p>
              <ul>
                <li>
                  Please note that swimming pools carry their own inherent
                  risks. Upon arrival at the property you and all members of
                  your party must take time to familiarise yourselves with the
                  location, layout, depth and how to activate the alarm of the
                  swimming pool. Young children must not be allowed to wander
                  unaccompanied in the grounds, and{" "}
                  <strong>
                    must be closely supervised at all times, it is your sole
                    responsibility.
                  </strong>
                </li>
                <li>
                  <strong>
                    There is a pool alarm fitted for you to activate:
                    instructions are provided. It is your responsibility to
                    familiarise yourself with the instructions for use, and to
                    activate the alarm. The alarm is to be active at all times
                    if any of your party cannot swim. The Owner / caretakers
                    cannot be held liable for accidents in and around the pool
                    area, and accepts no responsibility for the status of the
                    pool alarm at any time.
                  </strong>
                </li>
                <li>
                  <strong>The </strong>pool may not be available all year round.
                  If your rental period is outside of May – October please check
                  with the Owner that the pool will be open for use.
                </li>
              </ul>
              <p>
                <strong> </strong>
              </p>
              <p>
                <strong>SECURITY, VALUABLES &amp; LIABILITY</strong>
              </p>
              <p>
                <strong> </strong>
              </p>
              <ul>
                <li>
                  <strong>
                    The health and safety of you and all of your party is your
                    own responsibility.{" "}
                  </strong>
                  We suggest you take out a comprehensive Travel Insurance
                  policy for all guests for all sections of your travel and
                  stay.
                </li>
              </ul>
              <p>
                <strong> </strong>
              </p>
              <ul>
                <li>
                  <strong>
                    The Owner/caretaker accepts no responsibility or liability
                    for accidents or injuries sustained whilst on or off the
                    property, for the duration of your stay, for yourself of any
                    of your group. As with any large property, with unfenced
                    pools/fountains, unlandscaped/uneven areas, open steep
                    staircases, &amp; gym equipment; there are certain hazards
                    &amp; dangers: it is your responsibility to ensure the
                    welfare of yourselves &amp; others at all times throughout
                    the property. Each guest is to have their own travel
                    insurance covering personal injury and loss.
                  </strong>
                </li>
                <li>
                  <strong>
                    The Owner / caretaker cannot be held liable for loss or
                    theft of personal belongings taken from the property or
                    elsewhere
                  </strong>
                  <strong>. </strong>Any valuables left at the property are left
                  at your own risk. The Owner cannot be held responsible for any
                  loss. The safe is to be used and proper care be taken against
                  theft and burglary. It is your responsibility to ensure all
                  doors and windows are closed and locked when leaving the
                  property, or when not inside the property, ie. by the poolside
                  / in the grounds. If theft or damage to the property occurs
                  through negligence on your part the owner is entitled to seek
                  compensation from you.
                </li>
              </ul>
              <p>
                <b>
                  Booking Terms and Conditions for guests on an independently
                  run Retreat
                </b>
              </p>
              <p>
                For guests / students booked on / attending a retreat hosted by
                an independent host/facilitator/business (ie. not by a Casa
                Herta team member), please contact them for their booking
                conditions, as Casa Herta cannot be held liable in any way for
                any circumstances/liabilities or compensation that may occur
                prior, during or after a retreat held by an independent
                host/facilitator/business. All guests attending a retreat held
                on the Casa Herta premises must abide by the above Terms and
                Conditions of stay, including the strict noise policy,
                arrival/departure times, and strongly recommend taking out
                travel insurance.
              </p>
              <div>
                <b>
                  Booking Terms and Conditions for guests on a Casa Herta-hosted
                  Retreat{" "}
                </b>
              </div>
              <div></div>
              <div>
                Flights and travel insurance are not included. Any transfers /
                transport arrangements for times outside of the allocated group
                transfer must be paid for at own expense. To confirm a place on
                the retreat, a 50% booking deposit, as well as a signed retreat
                guest form is to be received. Retreat deposits are non
                refundable. Balance of payment is due 4 weeks prior to retreat
                start date; if the balance is not received, the place will be
                re-advertised, and the deposit retained. If the guest cancels
                their booking more than four weeks prior to retreat start date,
                the booking deposit (50% of price) will be retained. No refunds
                will be made for cancellations made within 4 weeks of retreat
                start date. The taking out of a travel insurance policy is
                recommended. If in the rare event the retreat does not meet
                minimum booking requirements and is cancelled, all deposits /
                balance of payments will be refunded. Bookings are transferrable
                and may be used by another person if the guest is unable to
                attend; the person taking the place must be comfortable within
                the same bedroom arrangements booked, and of the same gender if
                in a twin-share or triple/quad share room with persons they do
                not know.
              </div>
              <p>
                All guests attending a retreat held on the Casa Herta premises
                must abide by the above Terms and Conditions of stay, including
                the strict noise policy, and arrival/departure times.
              </p>
            </div>
          </Grid>
        </Grid>
        <Footer />
      </PageView>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    windowWidth: state.app.windowWidth,
    windowHeight: state.app.windowHeight,
  };
};

export default connect(mapStateToProps, null)(withStyles(styles)(Contact));
