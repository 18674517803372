import { Grid, withStyles } from "@material-ui/core";
import React, { Component } from "react";
import styles from "./Footer.styles";
import Logo from "../../assets/img/logo-neg.svg";
import { NavLink } from "react-router-dom";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

interface State {
}

interface OwnProps {
  classes?: any;
}

type Props = OwnProps;

class Footer extends Component<Props, State> {
  render() {
    const { classes } = this.props;
    return (
      <footer style={{ width: "100%" }}>
        <Grid container className={classes.root}>
          <Grid container direction="row" className={classes.footerHolder}>
            <div>
              <p>
                Website by&nbsp;{" "}
                <a
                  href="https://www.skillmedia.be"
                  target="_blank"
                  rel="noreferrer"
                  style={{ marginTop: 5 }}
                  className={classes.footerLink}
                >
                  <img src="/logo-skill.png" alt="" className={classes.imageLogo} style={{ width: 90 }} />
                </a>

                <NavLink
                  to="/terms"
                  style={{
                    marginRight: 30,
                    marginLeft: isMobile ? 0 : 30,
                    marginTop: isMobile ? 12 : 0
                  }}
                  className={classes.footerLinkUpdate}
                >
                  Terms &amp; Conditions
                </NavLink>
                {isMobile &&
                  <p>
                    <span>
                      HERTBELGIUM - Ringlaan 39, 1853 Strombeek-Bever (Belgium)
                    </span>
                    <span style={{ display: "block", marginTop: 12 }}>
                      BE0506743836
                    </span>
                  </p>
                }
                {!isMobile &&
                  <span>
                    HERTBELGIUM - Ringlaan 39, 1853 Strombeek-Bever (Belgium) -  BE0506743836
                  </span>
                }
              </p>
            </div>
            <div className={isMobile ? classes.divLogoFooter : ""}>
              <img src={Logo} alt="logo" style={{ width: 180, height: 75 }} />
            </div>
          </Grid>
        </Grid>
      </footer>
    );
  }
}

export default withStyles(styles)(Footer);
