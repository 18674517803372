const translations = {
    LANGUAGESELECT: "Kies je taal",
    HOME_TITLE: "Home – Boek nu jouw vakantienest op Ibiza!",
    HOME_WELCOME: "Welkom bij Casa Herta d'Ibiza",
    HOME_BOOK: "Boek nu jouw vakantienest op Ibiza",
    HOME_CHECKINOUT: "Inchecken - Uitchecken",
    ADULTS: "volwassene(n)",
    CHILDREN: "kind(eren)",
    HOME_CHECKAVAILABILITY: "controleer beschikbaarheid",
    HOME_TOPTEXT: (
        <>
            Bovenop de heuvels van Can Germa, tussen San Antonio en Santa Agnès, vind
            je deze prachtige villa in de traditionele stijl van Ibiza. Ze is volledig
            gerenoveerd met respect voor haar typisch Ibiziaanse karakter. De villa
            heeft alles wat jij en je gasten nodig hebben voor een perfecte vakantie
            op het eiland. Luier in de hangmat of op een ligbed aan het zwembad dat de
            hele dag in de zon ligt, of bezoek de stranden en beachclubs in de buurt,
            allemaal binnen 20 min rijden van de villa. De BBQ-plek buiten ligt in de
            schaduw en is perfect om met je gezin te ontbijten, met vrienden te
            lunchen of romantisch te tafelen met zicht op zee. Alle slaapkamers hebben
            hun eigen badkamer met douche of ligbad. De grote slaapkamer komt
            rechtstreeks uit op het bovenste terras met een bar en jacuzzi. Alle
            kamers zijn smaakvol ingericht, voorzien van alle gemak en uitgerust met
            moderne airconditioning voor jouw comfort.
        </>
    ),
    HOME_TOPTEXT2: (
        <>
            Luxueuze, typisch Ibiziaanse villa met 5 slaap-/badkamers, vlakbij Cala
            Salada, Eivissa
        </>
    ),
    HOME_VIEWSPECS: "bekijk alle specificaties van de villa",
    HOME_UNFORGETTABLE: "EEN ONVERGETELIJK VERBLIJF OP HET EILAND",
    HOME_WHY: "Waarom Ibiza altijd een goed idee is",
    HOME_WHYTEXT: (
        <>
            Het eiland dat alle harten inpalmt. Of je nu op zoek bent naar een relaxte
            vakantie, een avontuurlijke trip of de bloemetjes wil buitenzetten, Ibiza
            is altijd een goed idee. Elk jaar lokt het eiland meer dan 6 miljoen
            toeristen met zijn vele, gevarieerde blikvangers. Veel locals zeggen dat
            ze elke dag iets nieuws ontdekken op het eiland. Wil je een wandeltocht
            naar de verloren stad Atlantis maken, een boot huren om naar het magische
            eiland Formentera te zeilen of gewoon je dansschoenen aantrekken in de
            legendarische nachtclub Pacha ... dan is Ibiza jouw bestemming!
        </>
    ),
    HOME_LOCATION: (
        <>
            Casa Herta ligt op 5 min van het Cala Saladastrand, 10 min van San
            Antonio’s centrum, 20 min van Eivissa’s centrum en 20 min van de
            luchthaven.
        </>
    ),
    HOME_DISCOVER: "Ontdek onze Ibizatips",
    VILLA_LINK: "devilla",
    VILLA_LINK_TEXT: "De villa",
    GALLERY_LINK: "galerij",
    GALLERY_LINK_TEXT: "Galerij",
    DISCOVER_LINK: "ontdek",
    DISCOVER_LINK_TEXT: "Ontdek",
    CONTACT_LINK: "contacteer ons",
    CONTACT_LINK_TEXT: "Contact",
    RESERVATIONS_LINK_TEXT: "Reservaties",
    CONTACT_TITLE:
        "Contact - Contacteer ons en boek nu jouw vakantienest op Ibiza!",
    CONTACTUS: "CONTACTEER ONS",
    CONTACTSEND: "bericht verzenden",
    PROBLEM: "Probleem",
    PROBLEMSENDING: "Er is een probleem opgetreden tijdens het verzenden",
    PROBLEMFIELDS: "Niet alle velden zijn correct ingevuld",
    THANKYOU: "Dank je",
    THANKYOUMESSAGE:
        "Bedankt om ons te contacteren. Je hoort zo snel mogelijk van ons!",
    FIRSTNAME: "Voornaam",
    NAME: "Naam",
    EMAIL: "E-mail",
    MESSAGE: "Bericht",
    PHONE: "Telefoon",
    DISCOVERTEXT1: (
        <>
            Café Mambo viert zijn zilveren 25e verjaardag aan de Sunset Strip en is
            elke dag open van 10 mei 2019 tot midden oktober. De ultieme hotspot voor
            de meest iconische zonsondergang ter wereld en adembenemende Mediterrane
            uitzichten, lekker eten, ijskoude cocktails en zinderende dj-sets van
            supertalent van de elektronische scene. Café Mambo ligt in het hart van
            San Antonio, pal aan de Sunset Strip. Op tijd reserveren is een must om de
            beste zitjes te hebben!
        </>
    ),
    DISCOVERTEXT2: (
        <>
            Bekend om zijn live dj-sessies & opnames van chill-out, lounge, ambient,
            chill house en balearic beats. Café del Mar bracht onder eigen naam zijn
            eigen muziekgenre uit en verkocht de laatste decennia miljoenen platen
            wereldwijd. Vlak naast Café Mambo krijg je op deze hotspot dezelfde
            oogstrelende zeezichten en zonsondergangen te zien. Perfect voor een fris
            biertje 's avonds!
        </>
    ),
    DISCOVERTEXT3: (
        <>
            Een gezellige bar in de oude stad, dicht bij de haven. Mad Bar is de
            perfecte plek voor een cocktail als je het stadscentrum bezoekt. De bar
            ligt in de pittoreske steegjes van Ibiza’s versterkte oude stad en heeft
            een straatterras. Ze is in trek bij een ruimdenkend publiek.
        </>
    ),
    DISCOVERTEXT4: (
        <>
            Can Pou is in een gerenoveerd oud landhuis ondergebracht en ligt in de
            residentiële buurt Can Germa, buiten Sant Antoni en op weg naar de baaien
            van Cala Salada en Punta Galera. Een tuinterras onder de pijnbomen is
            ideaal voor het zomerseizoen en de gezellige eetkamer met open haard is
            perfect voor 's winters. De gerechten die op tafel komen in de gezellige
            tuin van Can Pou zijn eenvoudig bereid en leggen de nadruk op de
            grondstoffen, hoewel je er culinaire invloeden van overal in proeft. Het
            menu evolueert met de seizoenen mee, in functie van wat op de markt
            verkrijgbaar is.
        </>
    ),
    DISCOVERTEXT5: (
        <>
            Het eiland Formentera is een populaire vakantiebestemming dankzij zijn
            stralende stranden die tot de beste ter wereld behoren met hun
            overvloedige, gouden zand en kristalhelder, turkooizen water. Je kunt naar
            Formentera per jacht of overzetboot vanuit diverse havens op het eiland
            zoals Ibiza Town, Figueretas Beach en Playa d'en Bossa. Bij aankomst in de
            haven Puerto de La Savina geniet je niet alleen van de zonovergoten,
            spectaculaire stranden, maar ook van de waaier leuke activiteiten op
            Formentera: je kunt een motor huren om het eiland te verkennen, snorkelen
            ... Restauranttip (met je voeten in het zand): Beso Beach Formentera
        </>
    ),
    DISCOVERTEXT6: (
        <>
            Omdat de baai zo populair is in de zomermaanden moet je reserveren om
            teleurstellingen te vermijden. De Blue Marlin Ibiza is allicht een van de
            bekendste en exclusiefste beachclubs op het hele eiland, waar je lang kunt
            lunchen, après-beach cocktails drinken en een viptafel nemen als de dag
            nacht wordt. Naast de Blue Marlin ligt Yemanja, van dezelfde eigenaars.
            Deze idyllische hotspot bouwt zijn uitstekende reputatie al meer dan 30
            jaar op met traditionele Spaanse keuken en gezinsvriendelijke
            gezelligheid. De restaurant-strandbelevenis blijft niet beperkt tot luie
            lunches, er staat veel meer op het menu! Bij Yemanja beleef je de magie
            van het echte Ibiza, met zijn typische lunches, diners, strand, landschap,
            muziek en ambiance. 365 dagen per jaar open!
        </>
    ),
    DISCOVERTEXT8: (
        <>
            Verken het eiland met de fiets van zee tot heuvels en ervaar een opwindend
            gevoel van vrijheid. Elke fietstocht zit vol verrassende en afwisselende
            landschappen en panorama’s. Bike Ibiza kan fietsen aan de villa leveren en
            je trip begeleiden.
            <br />
            <br />
            <a href="mailto:info@casaherta.com">Contacteer ons om te boeken</a>
        </>
    ),
    DISCOVERTEXT9: (
        <>
            Ibiza heeft helder blauw water en is een onderzeese schatkamer. We bieden
            dagelijks boottochten aan om te snorkelen en de zonsondergang te
            bewonderen. Check onze zeebelevenissen voor elk publiek, van uitstapjes
            tot privéboottochten.
            <br />
            <br />
            <a href="mailto:info@casaherta.com">Contacteer ons om te boeken</a>
        </>
    ),
    DISCOVERTEXT10: (
        <>
            Ibiza is beroemd om zijn stranden en baaien. Ook het kleinere eiland
            Formentera is beslist een bezoek per boot waard.
            <br />
            <br />
            Facebook: Atzzo chatt
            <br />
            <br />
            Een boot huren? Een jetski huren?
            <br />
            <a href="mailto:info@casaherta.com">Contacteer ons om te boeken</a>
        </>
    ),
    DISCOVERTEXT11: (
        <>
            Pacha is onlosmakelijk verbonden met Ibiza. Met de jaren is de reputatie
            van de club gegroeid net zoals de reputatie van het eiland zelf. De locals
            dragen de club een warm hart toe en wie voor het eerst met vakantie is op
            Ibiza moet Pacha gezien hebben.
            <br />
            <br />
            Met ruim vier decennia partyplanning achter de kiezen en een grondige
            opknapbeurt in 2018 is de club tegenwoordig een van de grootste
            talentmagneten op het witte eiland. Maak je keuze uit de crème van
            electro, met alles van underground tot mainstream en oude klassiekers in
            een nieuw jasje.
            <br />
            <br />
            <strong>Bekend om</strong>: zijn kersen en viptafels, speeltuin van de
            rijken en de sterren, Ibiza's oudste, bekendste club
        </>
    ),
    DISCOVERTEXT12: (
        <>
            Zoals bijna overal op het Europese continent begint het clubleven op Ibiza
            meestal laat, echt laat. Voor veel feestvierders is het ongehoord om voor
            1 uur op pad te gaan, maar ze fuiven 's ochtends door tot in de vroege
            uurtjes. Veel iconische feestjes op het eiland stonden erom bekend pas van
            start te gaan nadat alles elders gesloten was. Amnesia opende zijn deuren
            oorspronkelijk rond 3 à 4 uur om feestgangers van de overkant uit de
            toenmalige Ku Club (nu Privilege) te verwelkomen. Maar naarmate de party
            scene op het eiland evolueerde, kwamen er nieuwe clubs bij met
            baanbrekende events en originele clubconcepten. Relatief nieuw op de
            Ibiziaanse clubscene is het Ushuaia Ibiza Beach Hotel dat in 2011
            openging. Zoals de naam aangeeft, is deze locatie in feite een hotel
            bestaande uit twee hoofdgebouwen: de Ushuaia Club en de Ushuaia Tower (die
            een jaar later opende in 2012), beide op de Platja d’en Bossa gelegen
            tussen de stad Ibiza en de luchthaven.
            <br />
            <br />
            Mensen komen vooral naar Ushuaia voor de fantastische openluchtfeestjes
            die rond het grote zwembad plaatsvinden. Door zijn ongewone openingsuren
            geeft deze club gasten de kans om de grootste dj’s van het eiland te zien
            draaien op klaarlichte dag en om schouder aan schouder met ze te staan als
            je in de viplounge mag.
            <br />
            <br />
            Later zal hun indoorclub Hï (ex-Space) haar deuren openen aan de overkant
            van de straat, dus het feest stopt nooit!
        </>
    ),
    DISCOVERTEXT13: (
        <>
            Lio Ibiza is de nieuwste entertainmentclub van de Ibiza Pacha Group. Een
            restaurant-cabaretmix waar je kunt dineren terwijl je naar live optredens
            en shows kijkt (dansers, burlesk, contorsionisten en veel meer). Bovendien
            lopen er de hele nacht acteurs en actrices rond om jou te entertainen. Een
            waar spektakel dat toch elegant en chic is. Een romantische omgeving met
            kaarslicht. Alles draait om een groot zwembad dat afhankelijk van de act
            overdekt wordt of openblijft. Het animatiepersoneel is overal in het
            restaurant aanwezig: charmezangers zingen aan jouw tafel als je wilt,
            kelners en serveersters dansen allerlei stijlen op de muziek en er zijn
            nog veel meer verrassingen. Lio Ibiza ligt op de toplocatie van de
            vroegere nachtclub El Divino, in het hart van Marina Botafoch, in
            Ibiza-stad. Het uitzicht op Dalt Vila (de ommuurde stad) is gewoon
            geweldig. En het terras geeft je het gevoel dat je vlakbij de zee bent.
            Het uitzicht is op zijn mooist 's nachts.
        </>
    ),
    DISCOVERTEXT14: (
        <>
            Dalt Vila behoort tot de best bewaarde steden in Europa en het is dan ook
            geen wonder dat dit oude stadsgedeelte toegevoegd is aan het werelderfgoed
            van Unesco. De tweeduizend jaar oude stad is een van de bekendste
            blikvangers. Kijk hoe deze plek van nachtelijke partytempel verandert in
            een 14e-eeuwse stad bij het ochtendgloren. De straatjes van Dalt Vila zijn
            altijd even uitnodigend, overdag én 's nachts. De locaties in de stad zijn
            een mix van oud en modern: links zie je luxemodewinkels en rechts
            middeleeuwse restaurants.
        </>
    ),
    DISCOVERTEXT15: (
        <>
            De kleine, beschutte baai van Cala Salada is omringd door heuvels met
            pijnbossen en is een favoriet van locals en booteigenaars. Het water is
            prachtig helder, ondiep en perfect om te zwemmen, en de zeebedding bestaat
            vooral uit zacht zand. Op het kleine strand is het erg druk in de
            zomerweekends en verspreiden mensen zich vaak over de rotsen die Cala
            Salada van het naburige Cala Saladita scheiden. Je kunt ook van de kliffen
            springen, maar wees voorzichtig en check eerst hoe diep het water is! Niet
            op alle plaatsen is springen veilig (ook al doen anderen dat). Op de
            uitstekende rotsen rechts staat een pittoreske stenen toren en leiden
            paden over de top naar de kleine strook zandstrand Cala Saladita. Links
            zijn er boothuizen ingebouwd in de rotsen. De goed begaanbare paden die
            naar de heuvels leiden, zijn populair om in dit ongerepte gebied te
            wandelen. Cala Salada ligt op wandelafstand van de villa.
        </>
    ),
    DISCOVERTEXT16: (
        <>
            Mensen die liever de overbevolkte stranden van Ibiza en zeker San Antonio
            vermijden, maar ook locals en avontuurlijke toeristen maken graag de tocht
            naar de rustige baai Cala Saladeta.
        </>
    ),
    DISCOVERTEXT17: (
        <>
            Voorbij Cala Gració ligt Cala Gracioneta. Dit is een tamelijk smal
            zandstrand met kristallijn, hemelsblauw water, toegankelijk via een
            natuurlijke rotsstrook. Het strand ligt tegenover het eilandje Sa
            Conillera, net als de naburige baai. Er is een chiringuito en je kunt er
            ligstoelen en zonneschermen huren.
            <br />
            Afstand: 1 km van Sant Antoni
        </>
    ),
    DISCOVERTEXT20: (
        <>
            Een van de adembenemendste uitzichten op Ibiza heb je bij Punta Galera,
            een prachtige baai omringd door steenformaties die uit de kliffen steken
            als grote, vlakke platen. Onder de rotsen nodigt de fonkelende;
            azuurblauwe Middellandse Zee zonnebaders uit om een verkoelende duik van
            de rotsen te nemen, terwijl omringende rotspoelen populair zijn bij
            snorkelaars. Een unieke Ibizabelevenis die je zeker niet mag missen. Omdat
            schaduw ontbreekt, bezoek je Punta Galera best 's ochtends vroeg of in de
            late namiddag, want het is niet makkelijk om een parasol vast te zetten
            tussen de rotsen! De zonsondergang is ronduit spectaculair vanaf dit
            uitkijkpunt.
        </>
    ),
    DISCOVERTEXT21: (
        <>
            Es Torrent is een van de rustigste, minder bezochte baaien op het eiland
            Ibiza. Dit ongerepte kiezelstrandje is omringd door schitterende kliffen
            en vormt een prima rustpunt in de buurt van Es Cubells, San José.
            <br />
            <br />
            Het unieke restaurant werd vernoemd naar de baai en is goed bekend. Even
            alles loslaten en lekker Ibiziaans eten zijn de belangrijkste redenen om
            neer te strijken op deze verborgen, ongerepte plek.
        </>
    ),
    DISCOVERTEXT22: (
        <>
            Bij de Cap d’es Falco, tussen de rimpelloze zoutvlaktes van Las Salinas,
            ligt Experimental Beach Ibiza aan het einde van een stoffige kronkelweg.
            Het strand is de rit beslist waard: als je aankomt bij het afgelegen
            strandrestaurant lijkt het wel of je in het paradijs belandt waar muziek,
            uitzicht, drankjes en eten samen iets heel speciaals creëren.
            <br />
            <br />
            De perfecte plek om een hele dag door te brengen. Overdag reserveer je in
            het strandrestaurant een luxueus zonnehemelbed met uitzicht over de
            fonkelende Middellandse Zee en nip je cocktails uit tropische
            hoornschelpen. 's Avonds maak je je op voor een spectaculaire natuurshow,
            om daarna te genieten van een heerlijk, lokaal geïnspireerd menu op het
            schaduwterras.
        </>
    ),
    DISCOVERTEXT23: (
        <>
            De zonsondergang van Ibiza is even iconisch als zijn beroemde nachtclubs
            en dj’s. Vraag je trouwe Ibizabezoekers of locals waar hun favoriete
            uitkijkpunt ligt, dan vertellen ze zeker over de zonsondergang bij
            restaurant Sunset Ashram. Hoog op de rotsen tussen de gouden zandstranden
            van Cala Conte, op de westkust van het eiland, draait Sunset Ashram
            helemaal om herbronnen in de omringende natuur. Je beleeft en voelt er de
            magische zonsondergang in plaats van hem gewoon te zien.. Net zoals dit
            uitkijkpunt een alternatief is voor de typische sunsetbars op Ibiza, is de
            muziek dat ook. In de relaxte, ongekunstelde restaurantruimte wisselen de
            beste balearic-dj’s elkaar af en draaien ze de perfecte soundtrack voor
            ontroerende zonsondergangen boven de Middellandse Zee. Sunset Ashram wordt
            nog aanlokkelijker dankzij zijn Aziatische fusionmenu met creaties om van
            te watertanden en samengesteld om met vrienden te delen. Het ultieme
            accessoire voor een zonsondergang is beslist een ijskoude cocktail en
            Sunset Ashram zal je niet teleurstellen ... wat je maar wil, ze hebben
            het.
        </>
    ),
    DISCOVERTEXT24: (
        <>
            Wil je meer tips en advies over Ibiza? Neem dan een kijkje op Besos de
            Ibiza. Deze website is gemaakt door digitale nomade Barbara die zowel
            Antwerpen als Ibiza haar thuis mag noemen. Op de website staat al wat je
            moet weten voor een perfecte vakantie op Ibiza!
            <br />
            <br />
            <a href="https://www.besosdeibiza.com" target="_blank" rel="noreferrer">
                www.besosdeibiza.com
            </a>
        </>
    ),
    DISCOVERTEXT25: (
        <>
            Casa Herta ligt op 25 min rijden van Ibiza Airport.
            <br />
            <br />
            <strong>Autoverhuur:</strong>
            <br />
            Contacteer ons voor de beste tarieven.
            <br />
            <br />
            <strong>Taxi:</strong>
            <br /> Neem altijd een taxi van de aangegeven taxistandplaats aan de
            terminal en vermijd taxichauffeurs die hun diensten elders aanbieden. De
            luchthaven heeft het jaar rond een permanente vloot met 69 taxi’s waarvan
            er drie speciaal uitgerust zijn voor mensen met een handicap. Heb je een
            taxi nodig en is de standplaats leeg, dan kun je Radio Taxi uit San José
            bellen (971 800 080).
            <br />
            Van juni tot en met september rijden er 70 extra taxi’s waarmee het totaal
            op 139 komt. Koffers en huisdieren worden gratis vervoerd. Vraag een
            rekening mocht je een klacht hebben.
            <br />
            <br />
            <strong>Meer info & gezondheid</strong>
            <br />
            Alle info over de luchthaven vind je op{" "}
            <a
                href="http://www.aena.es/en/ibiza-airport/index.html"
                target="_blank"
                rel="noreferrer"
            >
                http://www.aena.es/en/ibiza-airport/index.html
            </a>
            <br />
            <br />
            Surf naar{" "}
            <a
                href="http://wwwssl.aena.es/en/informationcovid19coronavirusairportsandflights.html"
                target="_blank"
                rel="noreferrer"
            >
                here
            </a>{" "}
            voor het laatste coronanieuws.
            <br />
            <br />
            <a href="mailto:info@casaherta.com">
                Contacteer ons voor meer informatie.
            </a>
        </>
    ),
    DISCOVERTITLE: "EEN ONVERGETELIJK VERBLIJF OP HET EILAND",
    DISCOVERSUBTITLE: "Waarom Ibiza altijd een goed idee is",
    FOODNDRINKS: "eten & drinken",
    ACTIVITIES: "activiteiten",
    SIGHTSEEING: "blikvangers",
    AIRPORT: "luchthaven",
    ISLANDMAP: "eilandkaart",
    VILLATEXT1: (
        <>
            Het weer op ibiza is meestal warm en aangenaam 's zomers en gematigd koud
            en draaglijk 's winters. <br />
            Het buitenzwembad wordt wellicht jouw favoriete stekje tijdens je
            vakantie. Met meerdere ligbedden, een chill-outplek, een eetruimte in de
            schaduw en natuurlijk een overloopzwembad zou je hier de hele dag kunnen
            blijven.
        </>
    ),
    VILLASPECS1: (
        <>
            <strong>Specificaties zwembadzone</strong>
            <br />
            Overloopzwembad
            <br />6 ligbedden
            <br />
            Eettafel 8p
            <br />
            Buiten-BBQ (elektrisch)
            <br />
            Hangmat
            <br />
            Loungesofa
            <br />
            Wc & wastafel
        </>
    ),
    VILLATEXT2: (
        <>
            Het bovenste terras is toegankelijk via de woonkamer en de grote
            slaapkamer. De bar is de perfecte plek voor een aperitiefje of een drankje
            laat op de avond. Aan de andere kant vind je de jacuzzi met een prachtig
            uitzicht over de baai van San Antonio. Relax in stijl!
        </>
    ),
    VILLASPECS2: (
        <>
            <strong>Specificaties terras</strong>
            <br />
            Buitenbar
            <br />
            Jacuzzi
            <br />
            Ligbedden
        </>
    ),
    VILLATEXT3: (
        <>
            De lichte, schone woonkamer is centraal gelegen in het huis en geeft
            toegang tot de zwembadzone via een grote schuifdeur, wat een ruim gevoel
            creëert. De open keuken is volledig vernieuwd en uitgerust met al wat je
            nodig hebt.
        </>
    ),
    VILLASPECS3: (
        <>
            <strong>Specificaties woonkamer</strong>
            <br />
            Tv
            <br />
            Kookvuur
            <br />
            Eettafel voor 6p
            <br />
            Amerikaanse koelkast
            <br />
            Sofa
            <br />
            Vaatwasser
        </>
    ),
    VILLATEXT4: (
        <>
            Je hebt toegang tot de villa via de oprit waar parkeerruimte is voor 1 of
            2 auto’s. Op de begane grond vind je een was- en bergruimte.
        </>
    ),
    VILLASPECS4: (
        <>
            <strong>Specificaties wasruimte</strong>
            <br />
            <br />
            Wasmachine
            <br />
            Droogkast
            <br />
            Strijkplank
        </>
    ),
    VILLATEXT5: <>Het huis heeft 4 slaapkamers..</>,
    VILLASPECS5: (
        <>
            <strong>Specificaties kamers</strong>
            <br />
            <br />
            Grote slaapkamer.
            <br />
            Kingsize bed
            <br />
            Badkamer met douche
            <br />
            Toegang tot het bovenste terras met jacuzzi
            <br />
            Airconditioning
            <br />
            <br />
            Room 2<br />
            Queensize bed
            <br />
            Badkamer met douche
            <br />
            Airconditioning
            <br />
            <br />
            Kamer 3<br />
            Kingsize bed
            <br />
            Badkamer met douche
            <br />
            Airconditioning
            <br />
            <br />
            Kamer 4<br />
            Queensize bed
            <br />
            2 eenpersoonsbedden
            <br />
            Badkamer met douche
            <br />
            Airconditioning
            <br />
            <br />
            Kamer 5<br />
            2 Queen Size Bedden
            <br />
            Badkamer
            <br />
            Airconditioning
        </>
    ),
    VILLAROOMS: "Kamers",
    VILLALAUNDRY: "Wasruimte",
    VILLALIVING: "Woonkamer & keuken",
    VILLALIVINGR: "Woonkamer",
    VILLATERRACE: "Terras",
    VILLAPOOL: "Zwembadzone",
    VILLAEXPLORE: "Verken Casa Herta d’Ibiza",
};

export default translations;
