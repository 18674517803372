const translations = {
    LANGUAGESELECT: "Choisissez votre langue",
    HOME_TITLE: "Accueil – Réservez votre refuge à Ibiza dès maintenant !",
    HOME_WELCOME: "Bienvenue à la Casa Herta d’Ibiza",
    HOME_BOOK: "Réservez votre refuge à Ibiza dès maintenant",
    HOME_CHECKINOUT: "Check-in – Check-out",
    ADULTS: "adulte(s)",
    CHILDREN: "enfant(s)",
    HOME_CHECKAVAILABILITY: "vérifier les disponibilités",
    HOME_TOPTEXT: (
        <>
            C’est au sommet des collines de Can Germa, entre San Antonio et Santa
            Agnès, que se trouve cette magnifique villa authentique de style ibérique,
            récemment rénovée dans le respect de son caractère typiquement ibizois. La
            villa inclut tout ce dont vos invités et vous avez besoin pour passer de
            merveilleuses vacances sur l’île. Détendez-vous dans le hamac ou sur l’un
            des transats installés au bord de l’espace piscine, constamment baigné de
            soleil, ou visitez l’une des plages ou l’un des beach clubs situés à 20
            minutes de route de la villa. Le coin repas extérieur avec barbecue est
            situé à l’ombre et constitue l’endroit idéal pour un petit-déjeuner en
            famille, un déjeuner entre amis ou un dîner romantique avec vue sur la
            mer. Toutes les chambres disposent d’une salle de bain privative avec
            douche ou baignoire. La chambre principale jouit d’un accès direct à la
            terrasse supérieure, dotée d’un coin bar et d’un jacuzzi. Toutes les
            chambres sont décorées et aménagées avec goût et sont équipées d’un
            système de climatisation de pointe.
        </>
    ),
    HOME_TOPTEXT2: (
        <>
            Superbe villa typique d’Ibiza avec 5 chambres/5 salles de bain située à
            proximité de Cala Salada, Eivissa
        </>
    ),
    HOME_VIEWSPECS: "voir toutes les caractéristiques de la villa",
    HOME_UNFORGETTABLE: "UN SÉJOUR INOUBLIABLE SUR L’ÎLE",
    HOME_WHY: "Pourquoi Ibiza est toujours une bonne idée",
    HOME_WHYTEXT: (
        <>
            Une île qui ne déçoit jamais. Que vous soyez en quête d’un séjour détente,
            d’une escapade riche en aventures ou de vacances placées sous le signe de
            la fête, Ibiza est l’endroit qu’il vous faut. Chaque année, l’île attire
            plus de 6 millions de touristes en raison de la diversité de ses sites. De
            nombreux habitants affirment y découvrir quelque chose de nouveau chaque
            jour. Alors, si vous voulez partir à la découverte de la cité perdue
            d’Atlantis, louer un bateau pour naviguer vers l’île magique de Formentera
            ou simplement faire chauffer vos chaussures de danse dans la légendaire
            boîte de nuit du Pacha, Ibiza est la destination faite pour vous !
        </>
    ),
    HOME_LOCATION: (
        <>
            La Casa Herta se situe à 5 minutes de la plage de Cala Salada, à 10
            minutes du centre de San Antonio, à 20 minutes du centre d’Eivissa et à 20
            minutes de l’aéroport.
        </>
    ),
    HOME_DISCOVER: "Découvrez toutes nos recommandations autour d’Ibiza",
    VILLA_LINK: "lavilla",
    VILLA_LINK_TEXT: "La villa",
    GALLERY_LINK: "galerie",
    GALLERY_LINK_TEXT: "Galerie",
    DISCOVER_LINK: "découvrir",
    DISCOVER_LINK_TEXT: "Découvrir",
    CONTACT_LINK: "contactez-nous",
    CONTACT_LINK_TEXT: "Contact",
    RESERVATIONS_LINK_TEXT: "Réservations",
    CONTACT_TITLE:
        "Contact – Contactez-nous et réservez votre refuge à Ibiza dès maintenant !",
    CONTACTUS: "CONTACTEZ-NOUS",
    CONTACTSEND: "envoyez votre message",
    PROBLEM: "Problème",
    PROBLEMSENDING: "Un problème est survenu durant l’envoi",
    PROBLEMFIELDS: "Tous les champs ne sont pas complétés correctement",
    THANKYOU: "Merci",
    THANKYOUMESSAGE:
        "Merci de nous avoir contactés. Nous reviendrons vers vous dès que possible !",
    FIRSTNAME: "Prénom",
    NAME: "Nom",
    EMAIL: "E-mail",
    MESSAGE: "Message",
    PHONE: "Téléphone",
    DISCOVERTEXT1: (
        <>
            Célébrant son 25e anniversaire sur le Sunset Strip, le Café Mambo est
            ouvert tous les jours du 10 mai à la mi-octobre. L’endroit idéal pour
            contempler les plus beaux couchers de soleil du monde et profiter de vues
            époustouflantes sur la Méditerranée, le Café Mambo propose des plats
            savoureux, de délicieux cocktails et des DJ sets palpitants, offerts par
            quelques-uns des plus grands talents de la scène électronique. Le Café
            Mambo se situe au cœur de San Antonio, le long du Sunset Strip.
            Assurez-vous de réserver suffisamment tôt pour profiter des meilleures
            places !
        </>
    ),
    DISCOVERTEXT2: (
        <>
            Connu pour ses live sets et ses enregistrements de morceaux chill-out,
            lounge, ambient, chill house et balearic beat, le Café del Mar est devenu
            un genre musical à part entière et a vendu des millions de disques dans le
            monde entier au cours des dernières décennies. Situé juste à côté du Café
            Mambo, ce haut lieu de la musique offre lui aussi des vues imprenables sur
            la mer et les couchers de soleil. Idéal pour déguster une bière bien
            fraîche en soirée !
        </>
    ),
    DISCOVERTEXT3: (
        <>
            Bar cosy situé dans la vieille ville, près du port, le Mad Bar est
            l’endroit idéal pour siroter un cocktail au centre-ville. Ayant élu
            domicile dans les petites rues pittoresques de la vieille ville fortifiée
            d’Ibiza, il est connu pour son ouverture d’esprit.
        </>
    ),
    DISCOVERTEXT4: (
        <>
            Aménagé dans une ancienne maison de campagne fraîchement rénovée, le Can
            Pou se situe dans le quartier résidentiel de Can Germa, à l’extérieur de
            Sant Antoni et sur la route des criques de Cala Salada et Punta Galera.
            Quoi de mieux qu’un jardin-terrasse sous les pins pour la saison estivale
            et qu’une salle à manger cosy avec foyer à bois pour la saison hivernale.
            Les plats servis dans l’accueillant jardin du Can Pou se caractérisent par
            une élaboration simple, qui accorde la prééminence à la matière première,
            mais sont également influencés par des traditions culinaires de
            différentes origines. Le menu est très saisonnier et varie constamment en
            fonction des produits du marché.
        </>
    ),
    DISCOVERTEXT5: (
        <>
            L’île de Formentera est une destination touristique très prisée pour ses
            magnifiques plages, classées parmi les plus belles du monde en raison de
            leurs sables dorés et de leurs eaux turquoise cristallines. Vous pouvez
            vous rendre à Formentera en yacht ou en ferry depuis de nombreux ports de
            l’île tels qu’Ibiza Town, Playa de Figueretas et Playa d’en Bossa. Une
            fois arrivé au Puerto de La Savina, en plus de profiter du soleil sur des
            plages spectaculaires, vous pourrez également vous adonner à toute une
            série d’activités amusantes à Formentera, comme louer une moto pour
            explorer l’île ou faire du snorkeling. Recommandation de restaurant (avec
            les pieds dans le sable) : Beso Beach Formentera
        </>
    ),
    DISCOVERTEXT6: (
        <>
            Vu la popularité de la crique durant l’été, il est essentiel de réserver
            afin d’éviter toute déception. Le Blue Marlin Ibiza est probablement l’un
            des beach clubs les plus célèbres et exclusifs de toute l’île. Il propose
            de longs déjeuners, des cocktails post-plage et des tables VIP une fois la
            nuit tombée. Juste à côté du Blue Marlin, vous trouverez le Yemanja, du
            même propriétaire. Un endroit idyllique qui forge sa réputation sur sa
            cuisine espagnole traditionnelle et son atmosphère familiale depuis plus
            de 30 ans. L’expérience gastronomique et balnéaire offerte sur place ne se
            limite pas à de simples « lunchs » ; l’endroit a bien plus à offrir ! Le
            Yemanja a su capturer la magie de l’esprit d’Ibiza à tous les niveaux,
            qu’il s’agisse du déjeuner, du dîner, de la plage, du paysage, de la
            musique ou encore de l’ambiance. Ouvert 365 jours par an !
        </>
    ),
    DISCOVERTEXT8: (
        <>
            Explorez l’île à vélo, de la mer aux collines, et profitez d’une agréable
            sensation de liberté. Chaque balade à vélo vous enchantera tant les
            paysages et les panoramas sont surprenants et variés. Bike Ibiza peut vous
            livrer des vélos à la villa et vous établir un itinéraire de balade.
            <br />
            <br />
            <a href="mailto:info@casaherta.com">
                Contactez-nous pour toutes vos réservations
            </a>
        </>
    ),
    DISCOVERTEXT9: (
        <>
            Véritable trésor sous-marin, Ibiza séduit par ses eaux bleues et
            cristallines. Nous organisons tous les jours des sessions de snorkeling et
            des croisières au coucher du soleil. Découvrez nos différentes expériences
            en mer, accessibles à tous les publics, qu’il s’agisse d’excursions ou
            encore de sorties en bateau privées.
            <br />
            <br />
            <a href="mailto:info@casaherta.com">
                Contactez-nous pour toutes vos réservations
            </a>
        </>
    ),
    DISCOVERTEXT10: (
        <>
            Ibiza est célèbre pour ses plages et ses baies. Même la plus petite île
            des Baléares, Formentera, est une destination incontournable en bateau.
            <br />
            <br />
            Facebook : Atzzo chatt
            <br />
            <br />
            Vous souhaitez louer un bateau ? Vous souhaitez louer un jet-ski ?
            <br />
            <a href="mailto:info@casaherta.com">
                Contactez-nous pour toutes vos réservations
            </a>
        </>
    ),
    DISCOVERTEXT11: (
        <>
            Le Pacha est inextricablement lié à Ibiza – au fil des ans, sa réputation
            s’est développée en même temps que celle de l’île. Le club est donc très
            apprécié des locaux et considéré comme un lieu incontournable pour les
            vacanciers dont c’est la première visite à Ibiza.
            <br />
            <br />
            Aujourd’hui, avec plus de quatre décennies d’organisation de fêtes à son
            actif et après avoir subi une rénovation massive en 2018, il peut se
            targuer de proposer l’une des plus longues listes de talents de l’île
            Blanche. Faites votre choix parmi la crème de la crème de la musique
            électronique, de l’underground au mainstream, en passant par les
            classiques du passé.
            <br />
            <br />
            <strong>Célèbre pour</strong>:ses cerises et ses tables VIP, terrain de
            jeu des personnalités riches et célèbres et club le plus ancien et le plus
            connu d’Ibiza
        </>
    ),
    DISCOVERTEXT12: (
        <>
            Comme dans la plupart des pays d’Europe continentale, les soirées à Ibiza
            commencent généralement tard, très tard – pour beaucoup, il est
            inconcevable de sortir faire la fête avant 1 heure du matin – et se
            poursuivent jusqu’aux petites heures du matin. Beaucoup des fêtes les plus
            emblématiques de l’île avaient la réputation de commencer une fois que
            tout le reste avait fermé. Ainsi, l’Amnesia ouvrait généralement ses
            portes vers 3 heures ou 4 heures du :matin afin d’accueillir les fêtards
            du Ku Club (aujourd’hui rebaptisé Privilege). Cependant, avec l’évolution
            de la scène de la nuit sur l’île, de nouveaux clubs ont vu le jour, avec
            de nouveaux événements et des concepts de clubbing originaux. Petit
            nouveau sur la scène de la nuit d’Ibiza, l’Ushuaia Ibiza Beach Hotel a
            ouvert ses portes en 2011. Comme son nom l’indique, l’endroit est en
            réalité un hôtel composé de deux bâtiments principaux : l’Ushuaia Club et
            l’Ushuaia Tower (qui a ouvert un an après, en 2012), tous deux situés sur
            la Platja d’en Bossa, entre la ville d’Ibiza et l’aéroport.
            <br />
            <br />
            L’Ushuaia est surtout prisé pour ses incroyables fêtes en plein air, qui
            se déroulent toutes autour de la piscine principale. Grâce à ses heures
            d’ouverture inhabituelles, le le club vous offre l’opportunité de voir
            certains des plus grands DJ de l’île jouer en plein jour – et de les
            côtoyer si vous parvenez à accéder au salon VIP.
            <br />
            <br />
            Ensuite, c’est le club intérieur Hï (anciennement Space), situé juste en
            face, qui prend le relais, pour que la fête ne s’arrête jamais !
        </>
    ),
    DISCOVERTEXT13: (
        <>
            Dernier club de divertissement ouvert par le groupe Pacha, le Lio Ibiza a
            réussi la fusion d’un restaurant et d’un cabaret où vous pouvez dîner tout
            en assistant à des représentations et spectacles en live (danse, numéros
            burlesques, contorsionnisme et bien plus encore). Des comédiens et
            comédiennes arpentent également les lieux afin de vous divertir tout au
            long de la nuit. Un véritable spectacle, élégant et chic. Un environnement
            romantique, peuplé de chandelles et avec pour point central une immense
            piscine, qui se couvre et se découvre en fonction du numéro. Des membres
            de l’équipe d’animation sont présents un peu partout dans le restaurant,
            des chanteurs peuvent entonner un petit air à votre table si vous le
            souhaitez, des serveurs et serveuses se déhanchent au rythme de la musique
            et bien plus encore. L’établissement jouit d’un emplacement privilégié, au
            sein de l’ancien club El Divino, au cœur de la Marina Botafoch, dans la
            ville d’Ibiza. Les vues sur Dalt Vila (la ville fortifiée) sont à couper
            le souffle. Et la terrasse vous donnera l’impression de vous trouver juste
            au-dessus de la mer. Les vues sont particulièrement belles la nuit.
        </>
    ),
    DISCOVERTEXT14: (
        <>
            Considérée comme l’un des centres historiques les mieux préservés
            d’Europe, pas étonnant que Dalt Vila ait intégré la liste des sites du
            patrimoine mondial de l’Unesco. Cette ville vieille de 200 ans est l’une
            des plus célèbres attractions d’Ibiza. De centre de la fête la nuit,
            l’endroit se métamorphose en ville du XIVe siècle à l’aube. Il n’y a pas
            vraiment d’heure pour se promener dans les rues de Dalt Vila, car elle est
            aussi belle le matin que le soir. Les établissements de la ville sont un
            mélange d’ancien et de nouveau. Ainsi, il n’est pas rare de voir des
            boutiques tendance côtoyer des restaurants médiévaux.
        </>
    ),
    DISCOVERTEXT15: (
        <>
            Entourée de collines couvertes de pins, la petite crique de sable protégée
            de Cala Salada est très prisée des résidents et des propriétaires de
            bateaux privés. L’eau y est transparente, peu profonde et idéale pour
            nager, et le fond de la mer est tapissé de sable mou. Cette petite plage
            est très fréquentée les week-ends d’été et souvent, les vacanciers
            étendent leurs serviettes sur les rochers qui séparent Cala Salada et Cala
            Saladita. Vous pouvez également sauter des falaises, mais soyez prudent.
            Vérifiez d’abord la profondeur de l’eau, car tous les endroits ne sont pas
            appropriés pour sauter (même si d’autres s’y risquent). Sur le promontoire
            rocheux situé sur la droite se trouve une pittoresque tour de pierre avec
            des sentiers menant à la petite plage de sable de Cala Saladita. Sur la
            gauche s’étendent des hangars à bateaux construits dans la roche. Les
            sentiers parfaitement aménagés menant aux collines sont très appréciés des
            promeneurs désireux de découvrir cette région préservée. Cala Salada se
            trouve à distance de marche de la villa.
        </>
    ),
    DISCOVERTEXT16: (
        <>
            Les personnes désireuses de fuir les plages surpeuplées d’Ibiza et San
            Antonio, ainsi que les locaux et les touristes aventureux prêts à partir
            en randonnée vers la paisible baie de Cala Saladita.
        </>
    ),
    DISCOVERTEXT17: (
        <>
            Après Cala Gració, il y a Cala Gracioneta. Il s’agit d’une plage de sable
            aux eaux turquoise plutôt étroite, accessible depuis une portion naturelle
            de parois rocheuses. Elle fait face à l’îlot Sa Conillera – comme sa
            crique voisine. Elle abrite un chiringuito, et des transats et des
            parasols sont disponibles à la location.
            <br />
            Distance : 1 km de Sant Antoni
        </>
    ),
    DISCOVERTEXT20: (
        <>
            L’un des sites les plus spectaculaires d’Ibiza, Punta Galera est une
            superbe baie entourée de formations rocheuses jaillissant des falaises
            telles d’immenses étagères plates. Sous les rochers, la mer Méditerranée
            scintille, invitant les adeptes du bronzage à plonger pour se rafraîchir
            un peu, tandis que les piscines rocheuses environnantes sont très
            appréciées des plongeurs avec tuba. Une expérience unique à Ibiza, à ne
            pas manquer. En raison du manque d’ombre, il est préférable de visiter
            Punta Galera tôt le matin ou en fin d’après-midi – pas facile d’installer
            un parasol dans les rochers ! Les couchers de soleil sont tout simplement
            spectaculaires depuis ce point d’observation.
        </>
    ),
    DISCOVERTEXT21: (
        <>
            Es Torrent est l’une des criques les plus tranquilles et les moins
            fréquentées de l’île d’Ibiza. Il s’agit d’une petite plage de galets
            immaculée, entourée de falaises resplendissantes, et d’un parfait lieu de
            détente situé dans la zone d’Es Cubells, San José.
            <br />
            <br />
            Le restaurant baptisé du nom de la plage est très réputé. Si vous êtes en
            quête de déconnexion et de mets de qualité, il est temps pour vous de
            pousser la porte de cet endroit sauvage et secret.
        </>
    ),
    DISCOVERTEXT22: (
        <>
            Située sur un site côtier appelé Cap d’es Falco, au milieu des marais
            salants de Las Salinas, l’Experimental Beach Ibiza s’étend au bout d’un
            chemin poussiéreux et sinueux. Arriver dans ce restaurant de bord de mer
            d’Ibiza, c’est un peu comme tomber sur le paradis. Sur place, musique,
            vues, boissons et plats s’associent pour créer quelque chose de vraiment
            spécial.
            <br />
            <br />
            L’endroit idéal pour passer toute une journée. Pendant la journée,
            réservez un superbe transat bohème face à la Méditerranée et sirotez des
            cocktails servis dans des conques d’inspiration tropicale ; le soir venu,
            assistez au plus beau spectacle nocturne que la nature a à offrir avant de
            déguster un délicieux menu d’inspiration locale sur la terrasse en plein
            air.
        </>
    ),
    DISCOVERTEXT23: (
        <>
            Le coucher de soleil d’Ibiza est aussi emblématique que les célèbres clubs
            et DJ de l’île. Demandez à n’importe quel vacancier régulier ou habitant
            de l’île où se trouve son point d’observation préféré et vous entendrez à
            coup sûr parler du restaurant Sunset Ashram. Perché entre les plages de
            sable doré de Cala Conte, sur la côte ouest de l’île, le Sunset Ashram
            vous permet de vous connecter à la nature qui vous entoure, de savourer le
            moment présent et de ressentir, plutôt que de simplement contempler,
            l’instant magique du coucher de soleil. Si la vue constitue une
            alternative aux bars à coucher de soleil emblématiques d’Ibiza, il en va
            de même pour la musique. L’espace restaurant, bohème et décontracté,
            accueille certains des meilleurs DJ des Baléares, la bande-son parfaite
            pour accompagner le soleil qui se couche sur la mer Méditerranée. Le menu
            de style fusion asiatique, avec ses créations alléchantes toutes conçues
            pour être partagées entre amis, ajoute encore à l’attrait du Sunset
            Ashram. Quoi de mieux pour contempler un coucher de soleil qu’un cocktail
            bien glacé. Ici aussi, le Sunset Ashram ne vous décevra pas... quel que
            soit votre souhait, il sera exaucé.
        </>
    ),
    DISCOVERTEXT24: (
        <>
            Envie de plus de conseils et de recommandations autour d’Ibiza ?
            Assurez-vous de consulter Besos de Ibiza. Un site Web créé par la nomade
            numérique Barbara, qui vit entre Anvers et Ibiza. Ce site Web contient
            tout ce que vous devez savoir pour passer des vacances parfaites à Ibiza !
            <br />
            <br />
            <a href="https://www.besosdeibiza.com" target="_blank" rel="noreferrer">
                www.besosdeibiza.com
            </a>
        </>
    ),
    DISCOVERTEXT25: (
        <>
            La Casa Herta se situe à 25 minutes de route de l’aéroport d’Ibiza
            <br />
            <br />
            <strong>Location de voitures:</strong>
            <br />
            Contactez-nous pour profiter des meilleurs tarifs
            <br />
            <br />
            <strong>Taxi:</strong>
            <br /> Prenez toujours votre taxi à la station de taxis indiquée au sein
            du terminal et évitez les chauffeurs de taxi qui proposent leurs services
            à partir d’autres endroits. L’aéroport dispose tout au long de l’année
            d’une flotte permanente de 69 taxis, dont trois sont spécialement équipés
            pour accueillir des personnes porteuses d’un handicap. Si vous avez besoin
            d’un taxi et qu’il n’y en a aucun à la station, vous pouvez contacter
            Radio Taxi de San José (971 800 080).
            <br />
            De juin à septembre inclus, 70 taxis supplémentaires sont en service, ce
            qui porte le nombre de taxis disponibles à 139. Le transport de bagages et
            d’animaux domestiques est gratuit. Exigez une facture en cas de plainte.
            <br />
            <br />
            <strong>Plus d’informations ; santé</strong>
            <br />
            Pour tout savoir sur l’aéroport, rendez-vous sur{" "}
            <a
                href="http://www.aena.es/en/ibiza-airport/index.html"
                target="_blank"
                rel="noreferrer"
            >
                http://www.aena.es/en/ibiza-airport/index.html
            </a>
            <br />
            <br />
            Consultez{" "}
            <a
                href="http://wwwssl.aena.es/en/informationcovid19coronavirusairportsandflights.html"
                target="_blank"
                rel="noreferrer"
            >
                here
            </a>{" "}
            pour connaître les dernières actualités liées au coronavirus
            <br />
            <br />
            <a href="mailto:info@casaherta.com">
                Contactez-nous pour de plus amples informations
            </a>
        </>
    ),
    DISCOVERTITLE: "UN SÉJOUR INOUBLIABLE SUR L’ÏLE",
    DISCOVERSUBTITLE: "Pourquoi Ibiza est toujours une bonne idée",
    FOODNDRINKS: "offre de nourriture et de boissons",
    ACTIVITIES: "activités",
    SIGHTSEEING: "tourisme",
    AIRPORT: "aéroport",
    ISLANDMAP: "carte de l’île",
    VILLATEXT1: (
        <>
            Le climat d’Ibiza se caractérise par des étés chauds et agréables et des
            hivers modérément froids et supportables. <br />
            L’espace piscine extérieur deviendra assurément votre endroit préféré
            pendant votre séjour. La zone comportant plusieurs transats, un coin
            détente, un coin repas à l’ombre et, bien sûr, une piscine à débordement,
            vous pourrez y passer la journée entière.
        </>
    ),
    VILLASPECS1: (
        <>
            <strong>Équipements de l’espace piscine</strong>
            <br />
            Piscine à débordement
            <br />6 transats
            <br />
            Table pour 8 personnes
            <br />
            Barbecue extérieur (électrique)
            <br />
            Hamac
            <br />
            Canapé de détente
            <br />
            Toilettes et lavabo
        </>
    ),
    VILLATEXT2: (
        <>
            La terrasse supérieure est accessible depuis la salle de séjour et la
            chambre principale. Le bar est l’endroit idéal pour un apéro ou un
            cocktail de fin de soirée. De l’autre côté, vous trouverez le jacuzzi, qui
            offre des vues imprenables sur la baie de San Antonio. Détendez-vous avec
            style !
        </>
    ),
    VILLASPECS2: (
        <>
            <strong>Équipements de la terrasse</strong>
            <br />
            Bar extérieur
            <br />
            Jacuzzi
            <br />
            Transats
        </>
    ),
    VILLATEXT3: (
        <>
            La salle de séjour est la partie centrale de la maison. Lumineuse et
            épurée, elle est équipée d’une grande porte coulissante s’ouvrant sur la
            piscine et offrant une sensation d’espace. La cuisine ouverte est
            entièrement équipée et comporte tous les aménagements nécessaires.
        </>
    ),
    VILLASPECS3: (
        <>
            <strong>Équipements de la salle de séjour</strong>
            <br />
            Télévision
            <br />
            Cuisinière
            <br />
            Table pour 6 personnes
            <br />
            Frigo américain
            <br />
            Canapé
            <br />
            Lave-vaisselle
        </>
    ),
    VILLATEXT4: (
        <>
            Accédez à la villa via l’allée, où se trouve une aire de stationnement
            pour 1 ou 2 véhicules. Une buanderie et un local de stockage se trouvent
            au rez-de-chaussée.
        </>
    ),
    VILLASPECS4: (
        <>
            <strong>Équipements de la buanderie</strong>
            <br />
            <br />
            Lave-linge
            <br />
            Sèche-linge
            <br />
            Table à repasser
        </>
    ),
    VILLATEXT5: <>La maison comporte 4 chambres.</>,
    VILLASPECS5: (
        <>
            <strong>Équipements de la chambre</strong>
            <br />
            <br />
            Chambre principale
            <br />
            Lit king-size
            <br />
            Salle de bain avec douche
            <br />
            Accès à la terrasse supérieure avec jacuzzi
            <br />
            Climatisation
            <br />
            <br />
            Room 2<br />
            Lit queen-size
            <br />
            Salle de bain avec douche
            <br />
            Climatisation
            <br />
            <br />
            Chambre 3<br />
            Lit king-size
            <br />
            Salle de bain avec douche
            <br />
            Climatisation
            <br />
            <br />
            Chambre 4<br />
            Lit queen-size
            <br />
            2 lits simples
            <br />
            Salle de bain avec douche
            <br />
            Climatisation
            <br />
            <br />
            Chambre 5<br />
            2 Lits queen-size
            <br />
            Salle de bain
            <br />
            Climatisation
        </>
    ),
    VILLAROOMS: "Chambres",
    VILLALAUNDRY: "Buanderie",
    VILLALIVING: "Salle de séjour et cuisine",
    VILLALIVINGR: "salle de séjour",
    VILLATERRACE: "Terrasse",
    VILLAPOOL: "Espace piscine",
    VILLAEXPLORE: "Explorez la Casa Herta d’Ibiza",
};

export default translations;
