const translations = {
    LANGUAGESELECT: "Choose your language",
    HOME_TITLE: "Home - Book your Ibiza hideaway now!",
    HOME_WELCOME: "Welcome to Casa Herta d'Ibiza",
    HOME_BOOK: "Book your ibiza hideaway now",
    HOME_CHECKINOUT: "Check in - Check out",
    ADULTS: "adult(s)",
    CHILDREN: "child(ren)",
    HOME_CHECKAVAILABILITY: "check availability",
    HOME_TOPTEXT: (
        <>
            At the top of the hills of Can Germa, in the middle of San Antonio and
            Santa Agnès, you can find this marvellous authentic ibician style villa.
            Highly renovated, with respect for his typical Ibiza character. The villa
            has everything you and your guests need for a perfect holiday on the
            Island. Relax in the hammock or on one of the sunbeds at the all-day-sun
            pool area or visit one of the the nearby beaches and beachclubs all in
            within a 20 min. drive from the villa. The outdoor dining area with bbq is
            located in the shade and perfect for breakfast with family, lunch with
            friends or a romantic dinner with seaview. All bedrooms have an ensuite
            bathroom with shower or bathtub. The master bedroom has a straight acces
            to the upper terrace with a bar area and jacuzzi. All rooms are decorated
            and equipped at high level and feature state of the art airconditioning
            for your comfort.
        </>
    ),
    HOME_TOPTEXT2: (
        <>
            Luxurious, Ibiza-style -5 Bedroom/Bathroom- Villa located near Cala
            Salada, Eivissa
        </>
    ),
    HOME_VIEWSPECS: "view full villa specifications",
    HOME_UNFORGETTABLE: "AN UNFORGETTABLE STAY ON THE ISLAND",
    HOME_WHY: "Why Ibiza is always a good idea",
    HOME_WHYTEXT: (
        <>
            The Island that always delivers. Whether you are looking for a relaxing
            vacation, an adventure trip are a party holiday, Ibiza is always a good
            idea. Each year the Island attract more then 6 million tourists due to its
            diverse range of sights. Many locals claim they discover something new on
            the island every day. So if you want to go for a hiking trip to discover
            the lost city of Atlantis, rent a boat to sail to magical Island of
            Formentera or just put on your dancing shoes at the legendary Pacha
            nightclub. Ibiza is your destination!
        </>
    ),
    HOME_LOCATION: (
        <>
            Casa Herta is located 5 min. from the Beach of Cala Salada, 10 min. from
            San Antonio centre, 20 min. from Eivissa centre and 20 minutes from the
            Airport.
        </>
    ),
    HOME_DISCOVER: "Discover our Ibiza tips",
    VILLA_LINK: "thevilla",
    VILLA_LINK_TEXT: "The villa",
    GALLERY_LINK: "gallery",
    GALLERY_LINK_TEXT: "Gallery",
    DISCOVER_LINK: "discover",
    DISCOVER_LINK_TEXT: "Discover",
    CONTACT_LINK: "contact-us",
    CONTACT_LINK_TEXT: "Contact",
    RESERVATIONS_LINK_TEXT: "Reservations",
    CONTACT_TITLE: "Contact - Contact us and book your Ibiza hideaway now!",
    CONTACTUS: "CONTACT US",
    CONTACTSEND: "send your message",
    PROBLEM: "Problem",
    PROBLEMSENDING: "Er is een probleem opgetreden tijdens het verzenden",
    PROBLEMFIELDS: "Niet alle velden zijn correct ingevuld",
    THANKYOU: "Thank you",
    THANKYOUMESSAGE:
        "Thank you for contacting us. We will get back to you as soon as possible!",
    FIRSTNAME: "Firstname",
    NAME: "Name",
    EMAIL: "Email",
    MESSAGE: "Message",
    PHONE: "Phone",
    DISCOVERTEXT1: (
        <>
            Celebrating a landmark 25th year on the sunset strip, Café Mambo is
            openevery day from May 10th 2019 until mid-October. The ultimate hotspot
            forthe world’s most iconic sunset and the most breathtaking Mediterranean
            views, with delicious food, ice-cold cocktails, and stunning DJ sets from
            thefinest talent in the electronic scene. Café Mambo is located in heart
            of SanAntonio, just along the Sunset Strip. Make sure to make your
            reservation in time for the best seats!
        </>
    ),
    DISCOVERTEXT2: (
        <>
            Known for it’s live dj sessions &amp; recordings of chill out, lounge,
            ambient, chill house and balearic beats selected tracks. Café del Mar
            became a very own musical genre, selling millions of records worldwide
            during the last decades. Right next to Café Mambo, this hotspot delivers
            the same stunning sea views and sunsets. Perfect for a fresh beer in the
            evening!
        </>
    ),
    DISCOVERTEXT3: (
        <>
            Cosy bar in the old town, close to the port. Mad Bar is the perfect place
            for a cocktail when visiting the city center. Located in the picturesque
            small streets of ibiza’s fortified old town street terrace, known for open
            minded people.
        </>
    ),
    DISCOVERTEXT4: (
        <>
            Can Pou is located in a renovated old country house. It is located in the
            residential area of Can Germa, outside of Sant Antoni and on the way to
            the coves of Cala Salada and Punta Galera. A garden-terrace under the pine
            trees is ideal for the summer season, and a cosy dining-room with
            wood-burning fireplace makes it perfect for winter climate. The dishes
            that are served in the cosy garden of Can Pou are characterized by a
            simple elaboration, which grants the prominence to the raw material,
            although influenced by culinary traditions from different origins. The
            menu is very seasonal and varies constantly, guided by the products of the
            market.
        </>
    ),
    DISCOVERTEXT5: (
        <>
            The island of Formentera is a popular tourist destination for its
            magnificent beaches that have been ranked among the best in the world for
            their rich golden sands and turquoise crystal line waters. You can get to
            Formentera by yacht or by ferry from many ports on the island such as
            Ibiza Town, Figueretas Beach and Playa d'en Bossa. Once you arrive at the
            port Puerto de La Savina, apart from soaking up the sun on its spectacular
            beaches, there is also a range of fun activities you can enjoy in
            Formentera such as renting a motorbike to explore the island or going
            snorkelling. Restaurant (with your feed in the sand) tip: Beso Beach
            Formentera
        </>
    ),
    DISCOVERTEXT6: (
        <>
            Due to the popularity of the cove in the summer months, reservations are
            essential to avoid disappointment. The Blue Marlin Ibiza is perhaps the
            one of the most famous and exclusive beach clubs on the entire island,
            offering long lunches, après-beach cocktails and VIP tables as day turns
            tonight. Next to Blue Marlin, and from the same owners, you can find
            Yemanja. An idyllic hotspot that has built its outstanding reputation on
            traditional Spanish cuisine and family-friendly ambiance for more than 30
            years. The overall restaurant and beach experience is not limited to just
            "lazy lunches" but there is much more on offer! Yemanja captures the magic
            of the true spirit of Ibiza for lunch, dinner, beach, landscape, music and
            ambiance. Open 365 days a year!
        </>
    ),
    DISCOVERTEXT8: (
        <>
            Explore the island by bike from the sea to the hills and experience the
            thrilling sensation of freedom. Every bike ride will amaze you as the
            landscapes and panoramas are astonishing and diverse. Bike Ibiza can
            deliver bikes to the villa and guide your trip.
            <br />
            <br />
            <a href="mailto:info@casaherta.com">Contact us for bookings</a>
        </>
    ),
    DISCOVERTEXT9: (
        <>
            Ibiza has blue and crystal clear waters and is a treasure under the sea.
            We offer daily snorkelling boat trips and sunset trips check out the
            variety of our sea experiences valid for all audiences, from excursions to
            private boat trips.
            <br />
            <br />
            <a href="mailto:info@casaherta.com">Contact us for bookings</a>
        </>
    ),
    DISCOVERTEXT10: (
        <>
            Ibiza is famous for his beaches and bays. Also the smaller Island
            Formentera is a must go-to destination by boat.
            <br />
            <br />
            Facebook: Atzzo chatt
            <br />
            <br />
            Want to rent a boat? Want to rent Jetski?
            <br />
            <a href="mailto:info@casaherta.com">Contact us for bookings</a>
        </>
    ),
    DISCOVERTEXT11: (
        <>
            Pacha is inextricably linked with Ibiza - over the years, its reputation
            has grown in line with the reputation of the island itself. As a result,
            the club is held dearly in the affections of locals and considered a "must
            visit" for holidaymakers making their Ibiza debut.
            <br />
            <br />
            These days, with over four decades of party-planning under its belt, as
            well as a massive refurb in 2018, it boasts one of the biggest rosters of
            talent on the White Isle. Take your pick from the cream of the
            electronicmusic crop, with everything from underground to mainstream to
            classics from the past being represented.
            <br />
            <br />
            <strong>Famous for</strong>:The cherries, VIP tables, playground of the
            rich and famous, being Ibiza's oldest, most well-known club
        </>
    ),
    DISCOVERTEXT12: (
        <>
            Like most places in mainland Europe, clubbing in Ibiza usually starts
            late, really late – for many the idea of heading out to party before 1am
            is unheard of – and goes on until the early hours of the morning. Many of
            the island’s most iconic parties gathered a reputation for opening up
            after everywhere else had shut, Amnesia originally opened its doors around
            3am or 4am to welcome revellers from across the road at the then Ku
            club(now Privilege). But as the party scene on the island evolved, new
            clubs emerged, pioneering new events and original clubbing concepts. A
            relative newcomer on the Ibizan club scene, Ushaia Ibiza Beach Hotel
            opened its doors in 2011. As its name indicates, the venue is in fact a
            hotel formed of two main buildings: the Ushuaia Club and the Ushuaia Tower
            (which opened a year later in 2012), both located on the Platja d’en Bossa
            between Ibiza town and the airport.
            <br />
            <br />
            People mostly come to Ushuaia is for the amazing open-air parties which
            take place around the main pool. Owing to its unusual opening hours, the
            club offers the opportunity to see some of the biggest DJs on the island
            play in broad daylight – and brush shoulders with them too if you make it
            into the VIP lounge.
            <br />
            <br />
            Later on their indoor club Hï (ex-Space) will open its doors right across
            the street, so the party never stops!
        </>
    ),
    DISCOVERTEXT13: (
        <>
            Lio Ibiza is the latest entertainment club of the Ibiza Pacha Group. A mix
            of restaurant and cabaret where you can have dinner while watching live
            performances and shows (dancers, burlesque acts, contortionists, and much
            more), plus, there’s actor and actresses which go all over the place
            entertaining you all night long. A true spectacle, yet elegant and chic. A
            romantic environment, with candlelights. Everything turns around a big
            pool which depending on the act, they cover it or leave it open. The
            animation staff is all over the restaurant, ballad singers who sing at
            your table if you wish, waiters and waitresses dancing the most varied
            styles depending on the music that’s on, and many more surprises... It’s
            location is in a real privileged spot, at the former El Divino night club,
            in the heart of Marina Botafoch, in Ibiza town. The sights to Dalt Vila
            (the Walled City) are just amazing. And the terrace gives the feeling that
            you’re right over the sea. The views are especially beautiful at night
            time.
        </>
    ),
    DISCOVERTEXT14: (
        <>
            Considered as one of the most well preserved towns in Europe there is no
            wonder why Dalt Vila has been added to UNESCO’s Word Heritage Sites. The
            two-thousand year old town is one of the city’s most famous highlights.
            Watch this place transform from party central at night into a town from
            the 1300s by dawn. There really is no off-time for walking the streets of
            Dalt Vila as it is as beautiful from morning until evening. The
            establishments in town are a mix of ancient and modern where we can see
            high-end fashion boutiques on the left and Medieval restaurants on the
            right.
        </>
    ),
    DISCOVERTEXT15: (
        <>
            Surrounded by pine forested hills, thesmall, protected sandy coveofCala
            Salada is a favourite for residents and private boat owners. The water is
            beautifully clear, shallow and perfect for swimming, and thesea bed mostly
            soft sand. This small beach gets very busy on summer weekends, with people
            often spread across the rocks that separate Cala Salada and neighbouring
            Cala Saladita. You can also jump from the cliffs, but please be careful
            and check the depth of the water first, it is not safe to jump from all
            places (even if others do).On the rocky promontory to the right is a
            picturesque stone tower with paths leading over the top to thelittle
            fringe of sandy beach of CalaSaladita. To the left are boathouses built
            into the rock. Well trodden paths leading into the hills are popular for
            walks in this unspoiled area. Cala Salada is at walking distance from the
            villa.
        </>
    ),
    DISCOVERTEXT16: (
        <>
            Those wanting to escape the overcrowded beaches of Ibiza and definitely
            San Antonio, as well as Ibiza locals and adventurous tourists who are
            willing to make the trek towards the tranquil bay of Cala Saladeta.
        </>
    ),
    DISCOVERTEXT17: (
        <>
            Following Cala Gració there is Cala Gracioneta. It is quite a narrow sand
            beach, with pristine turquoise waters, accessible from a natural stretch
            of rocky walls. It faces the islet Sa Conillera –like its neighbouring
            cove-. There is a chiringuito and sun lounges and sun shades for hire.
            <br />
            Distance: 1 km from Sant Antoni
        </>
    ),
    DISCOVERTEXT20: (
        <>
            One of the most breathtaking sights to behold in Ibiza, Punta Galera is an
            amazing bay surrounded by stone formations jutting out from the cliffs
            like large, flat shelves. Beneath the rocks, the sparkling azure
            Mediterranean Sea glistens, beckoning sunbathers to cool off by diving off
            the rocks, while surrounding rock pools prove popular with snorkelers. A
            truly unique Ibiza experience and one that shouldn’t be missed. Due to the
            lack of shade, it’s best to visit Punta Galera either early in the morning
            or late in the afternoon – it’s not very easy to balance an umbrella in
            the rocks! Sunsets are simply spectacular from this vantage point.
        </>
    ),
    DISCOVERTEXT21: (
        <>
            Es Torrent is one the quietest and least frequented coves in the island of
            Ibiza. It is an untouched small pebbly beach surrounded by resplendent
            cliffs and an excellent relaxing spot located in the area of Es Cubells,
            San José.
            <br />
            <br />
            The unique restaurant that takes the same name as the beach is very
            renowned. To disconnect and to have an Ibizan quality food are the main
            reasons to set foot in this hidden and pristine place.
        </>
    ),
    DISCOVERTEXT22: (
        <>
            Situated on a coastal spot called Cap d’es Falco, among the glass-still
            salt flats of Las Salinas, Experimental Beach Ibiza is located at the end
            of a dusty, winding track, and it’s well worth the ride – arriving at this
            secluded seaside Ibiza beach restaurant is a bit like stumbling across
            paradise, where music, views, drinks and food all converge to create
            something truly special.
            <br />
            <br />
            The perfect place to spend a whole day. By day at the Ibiza beach
            restaurant, reserve a luxe bohemian sunbed facing out towards the
            sparkling Mediterranean, all the while sinking cocktails served in
            tropical-inspired conch shells; by night line up for nature’s most
            spectacular evening show before tucking into a delectable locally inspired
            menu on the alfresco terrace.
        </>
    ),
    DISCOVERTEXT23: (
        <>
            The Ibiza sunset is as iconic as the island’s famous night clubs and DJs,
            and ask any long-term visitor or local where their favourite vantage point
            is and you’ll be sure to hear about Ibiza sunset restaurant Sunset Ashram.
            Perched on the peak between the golden sandy beaches of Cala Conte, on the
            west coast of the island, Sunset Ashram is all about connecting with the
            nature around you, appreciating the moment and feeling, rather than just
            viewing, the magical sunset moment. Just as the view is an alternatve to
            the typical Ibiza sunset bars, so too is the music. The laidback, bohemian
            restaurant space offers a rotating roster of the best Balearic DJs
            providing the perfect musical soundtrack to the emotive sunset scenes over
            the Mediterranean Sea. Adding further to the appeal of Sunset Ashram is
            the Asian fusion style menu, with mouthwatering creations all designed to
            share between friends. Of course, the ultimate sunset accessory is an icy
            cold cocktail and Sunset Ashram do not disappoint... whatever your
            craving, they’ve got you covered.
        </>
    ),
    DISCOVERTEXT24: (
        <>
            Want more tips and recommendations about Ibiza? Make sure to check out
            Besos de Ibiza. A website by digital nomad Barbara, who can call both
            Antwerp as Ibiza as her home. The website covers all you need to know for
            a perfect Ibiza holiday!
            <br />
            <br />
            <a href="https://www.besosdeibiza.com" target="_blank" rel="noreferrer">
                www.besosdeibiza.com
            </a>
        </>
    ),
    DISCOVERTEXT25: (
        <>
            Casa Herta is located 25 min from Ibiza Airport by car
            <br />
            <br />
            <strong>Car rental:</strong>
            <br />
            For the best rates contact us
            <br />
            <br />
            <strong>Taxi:</strong>
            <br /> Always take a taxi from the signed taxi rank at the terminal and
            avoid taxi drivers offering services from other points. The airport has a
            permanent fleet of 69 taxis throughout the whole year, three of which are
            specially equipped for people with disabilities. If you need a taxi, and
            there aren't any at the rank, you can call Radio Taxi de San José (971 800
            080).
            <br />
            From June to September, both inclusive, 70 additional taxis are in
            operation, bringing the total to 139. Suitcases and pets are carried free
            of charge. Demand a bill in the case of any complaint.
            <br />
            <br />
            <strong>More info &amp; health</strong>
            <br />
            For all info about the airport check out{" "}
            <a
                href="http://www.aena.es/en/ibiza-airport/index.html"
                target="_blank"
                rel="noreferrer"
            >
                http://www.aena.es/en/ibiza-airport/index.html
            </a>
            <br />
            <br />
            Check out{" "}
            <a
                href="http://wwwssl.aena.es/en/informationcovid19coronavirusairportsandflights.html"
                target="_blank"
                rel="noreferrer"
            >
                here
            </a>{" "}
            for the latest corona news
            <br />
            <br />
            <a href="mailto:info@casaherta.com">Contact us for more information</a>
        </>
    ),
    DISCOVERTITLE: "AN UNFORGETTABLE STAY ON THE ISLAND",
    DISCOVERSUBTITLE: "Why Ibiza is always a good idea",
    FOODNDRINKS: "food & drinks",
    ACTIVITIES: "activities",
    SIGHTSEEING: "sightseeing",
    AIRPORT: "airport",
    ISLANDMAP: "island map",
    VILLATEXT1: (
        <>
            The weather in ibiza is mainly characterized by warm and pleasant summers
            and mildly cold and tolerable winters. <br />
            No doubt, the outdoor pool area will be your favourite hideout during your
            holiday. With multiple sunbeds, a chill-out, a dining area in the shade
            and of course an infinite pool, you could spend the whole day here
        </>
    ),
    VILLASPECS1: (
        <>
            <strong>Pool area specifications</strong>
            <br />
            Infinite pool
            <br />6 sunbeds
            <br />
            Dining table 8p
            <br />
            Outdoor bbq (electric)
            <br />
            Hammock
            <br />
            Chill-out sofa
            <br />
            Toilet &amp; sink
        </>
    ),
    VILLATEXT2: (
        <>
            The upper terrace is accessible from both the living room as from the
            master bedroom. The bar is the perfect place for an apero or a late night
            drink. At the other side you can find the jacuzzi with amazing views of
            San Antonio Bay. Relax in style!
        </>
    ),
    VILLASPECS2: (
        <>
            <strong>Terrace specifications</strong>
            <br />
            Outdoor bar
            <br />
            Jacuzzi
            <br />
            Sunbeds
        </>
    ),
    VILLATEXT3: (
        <>
            The living room is the central area of the house, bright and clean with a
            large sliding door to the pool area for a very spacious feeling. The open
            kitchen is fully equipped and renewed with all necessities.
        </>
    ),
    VILLASPECS3: (
        <>
            <strong>Living room specifications</strong>
            <br />
            TV
            <br />
            Cooking fire
            <br />
            Dining table for 6p
            <br />
            American fridge
            <br />
            Sofa
            <br />
            Dishwasher
        </>
    ),
    VILLATEXT4: (
        <>
            Enter the villa through the driveway where you’ll have parking space for 1
            or 2 cars. At the ground level you can find a laundry and storage room.
        </>
    ),
    VILLASPECS4: (
        <>
            <strong>Laundry room specifications</strong>
            <br />
            <br />
            Washing machine
            <br />
            Dryer
            <br />
            Ironing board
        </>
    ),
    VILLATEXT5: <>The house offers 5 bedrooms.</>,
    VILLASPECS5: (
        <>
            <strong>Room specifications</strong>
            <br />
            <br />
            Master Bedroom
            <br />
            King Size Bed
            <br />
            Bathroom with Shower
            <br />
            Access to the upper terrace with jacuzzi
            <br />
            Airconditioning
            <br />
            <br />
            Room 2<br />
            Queen Size Bed
            <br />
            Bathroom with Shower
            <br />
            Airconditioning
            <br />
            <br />
            Room 3<br />
            King Size Bed
            <br />
            Bathroom with Shower
            <br />
            Airconditioning
            <br />
            <br />
            Room 4<br />
            Queen Size Bed
            <br />
            2 Single beds
            <br />
            Bathroom with Shower
            <br />
            Airconditioning
            <br />
            <br />
            Room 5<br />
            2 Queen Size Beds
            <br />
            Bathroom
            <br />
            Airconditioning
        </>
    ),
    VILLAROOMS: "Rooms",
    VILLALAUNDRY: "Laundry room",
    VILLALIVING: "Living Room & kitchen",
    VILLALIVINGR: "living room",
    VILLATERRACE: "Terrace",
    VILLAPOOL: "Pool area",
    VILLAEXPLORE: "Explore Casa Herta d’Ibiza",
    MAPDESC: (
        <>
            <p>Looking for the hotspots on the Island?
                <br />
                Download our Ibiza Guide and get to know our favourite restaurants, beaches and hangouts.
            </p>
            <p>
                Submit your e-mail to receive the ultimate Ibiza guide (NL) by mail.
            </p>
            <input type="text"/>
            <button>SUBMIT</button>
        </>
    )
};

export default translations;
